import React from 'react'
import { defaultColors, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { createStyles, withStyles } from '@material-ui/core'
import { WithTooltip } from './withTooltip'

export function _Link({ classes, tooltipText, onClick, className, children }: MuiProps & React.PropsWithChildren<Props>) {
    return (
        <WithTooltip text={tooltipText}>
            <span onClick={onClick ?? noop} className={classes.link + (className ? ' ' + className : '')}>
                {children}
            </span>
        </WithTooltip>
    )
}

type Props = {
    onClick?: () => void
    tooltipText?: string
    className?: string
}

const noop = () => { }

const styles = _ => createStyles({
    link: {
        color: defaultColors.lightBlue.light.color,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: '#BCD6FE'
        }
    }
})

export let Link = withStyles(styles, muiOptions)(_Link)