import React, { useState, useRef, useImperativeHandle, RefObject } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, createStyles, withStyles, Typography } from '@material-ui/core'
import { defaultColors, defaultStyles, muiOptions } from '../../../../../../infrastructure/materialUiThemeProvider'
import * as Icons from '@material-ui/icons'
import * as api from '../../../../../../infrastructure/api'
import { t } from '../../../../../../infrastructure/i18nextHelper'
import { Button } from '../../../../../common/customComponents'
import { MovementForm } from '../../../../stockModels'

function _PoReleaseDialog(props) {
    const [open, setOpen] = useState<boolean>(false)
    const [poReleaseDetails, setPoReleaseDetails] = useState<PoReleaseDetail[] | null>(null)
    const movementRef = useRef<MovementForm | null>(null)
    const movementReloaderRef = useRef<(() => Promise<void>) | null>(null)
    dialogRef = useRef<{ open: (entity: OpenPoReleaseDetails) => void }>(null)

    useImperativeHandle(dialogRef, () => ({
        open: async (parameter: OpenPoReleaseDetails) => {
            setPoReleaseDetails(null)
            let result = await api.get<PoReleaseDetail[]>('sapApi/purchaseOrderValidators',
                {
                    referenceOrder: parameter.movement.mainSapFlow.referenceOrder,
                    company: parameter.movement.company,
                    movementId: parameter.movement.id
                })
            setPoReleaseDetails(result)
            movementRef.current = parameter.movement
            movementReloaderRef.current = parameter.movementReloader
            setOpen(true)
        }
    }))

    const onRelease = async () => {
        let toBeReleaseCode = poReleaseDetails?.find(x => x.state == 'waiting')?.code
        await api.post('sapApi/purchaseOrderRelease', {
            referenceOrder: movementRef.current!.mainSapFlow.referenceOrder, releaseCode: toBeReleaseCode,
            company: movementRef.current!.company, movementId: movementRef.current!.id
        })
        await (movementReloaderRef.current!)()
        setOpen(false)
    }

    let getIcon = (state: ReleaseState) => {
        switch (state) {
            case 'released':
                return <Icons.Check className={props.classes.releasedIcon} />;
            case 'waiting':
                return <Icons.ChangeHistory className={props.classes.waitingIcon} />
            default:
                return <Icons.Remove />
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                scroll={'paper'}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'>
                <DialogTitle>{t('stock.label.movement.sapAction.releaseDialogTitle')}</DialogTitle>
                <DialogContent dividers={true} className={props.classes.noPadding + ' ' + props.classes.minSize}>
                    {poReleaseDetails?.map(poRelease => (
                        <div key={poRelease.order} className={props.classes.line}>
                            {getIcon(poRelease.state)}
                            <Typography>{poRelease.code} - {poRelease.description}</Typography>
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        label={t('components.dialogClose')}
                        onClick={() => setOpen(false)}
                        color='primary'
                        className={props.classes.closeButton} />
                    <Button
                        label={t('components.dialogConfirm')}
                        color='primary'
                        onClick={onRelease}
                        className={props.classes.confirmButton} />
                </DialogActions>
            </Dialog>
        </>
    )
}

type PoReleaseDetail = {
    code: string
    description: string
    state: ReleaseState
    order: number
}

type ReleaseState = "released" | "waiting" | null

export type OpenPoReleaseDetails = {
    movement: MovementForm,
    movementReloader: () => Promise<void>
}

let dialogRef: RefObject<{ open: (entity: OpenPoReleaseDetails) => void }> | null = null

let styles = _ =>
    createStyles({
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        closeButton: { ...defaultStyles.dialogCloseButton },
        line: { ...defaultStyles.flexRow, alignItems: 'flex-end' },
        title: { marginRight: '0.5em' },
        releasedIcon: {
            color: defaultColors.green.light.color
        },
        waitingIcon: {
            color: defaultColors.orange.light.color
        }
    })

export let poReleaseDetailsDialog = { open: (args: OpenPoReleaseDetails) => dialogRef?.current?.open(args) }
export let PoReleaseDetailsDialog = withStyles(styles, muiOptions)(_PoReleaseDialog)