import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { DeleteOutlined, GavelOutlined, TrendingFlat, FileCopyOutlined, Link } from '@material-ui/icons'
import {
    Table, TableBody, TableHead,
    TableRow, IconButton, Tooltip, Badge
} from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { Guid } from '../../../../infrastructure/guid'
import { popupNavigator } from '../../../../infrastructure/popupNavigator'
import { hasFeature } from '../../../../infrastructure/feature'
import { Claims } from '../../../../infrastructure/signIn/models'
import { hasClaim } from '../../../../infrastructure/signIn/userContext'
import { TextField, NumberField, Select, DatePicker, DeltaNumberLabel } from '../../../common/customComponents'
import { commonStyle, Cell, RightAlignedCell } from './_common'
import { VesselEditContainer } from '../vesselEditStore'
import { VesselProduct, PurchaseMovement, SitePurchaseMovement } from '../../vesselModels'
import { Item } from '../../../common/components/select/types'

let tBase = 'vessels.label.purchaseMovement.'

type PurchaseMovementTableProps = {
    vesselProduct: VesselProduct
    purchaseMovement: PurchaseMovement
    canDeletePurchaseMovement: boolean
    isFixedPrice: boolean
    addSite: (purchaseMvt: PurchaseMovement, defaultSitePurchaseMovement: SitePurchaseMovement) => void
    createSite: (company: string, dutyStatus: string) => SitePurchaseMovement
    duplicateSite: (site: SitePurchaseMovement) => SitePurchaseMovement
    setPurchaseMovementToDelete: () => void
    setSitePurchaseMovementToDelete: (site: SitePurchaseMovement) => void
}

type PricingNeedDeltasBySiteId = {
    [siteId: string]: number | null
}

function _PricePurchaseMovementTable(props: PurchaseMovementTableProps & MuiProps) {
    let { classes, vesselProduct, purchaseMovement, isFixedPrice,
        duplicateSite, addSite, setPurchaseMovementToDelete, setSitePurchaseMovementToDelete } = props

    let initPricingNeedDeltas = (): PricingNeedDeltasBySiteId =>
        purchaseMovement.sites.reduce((acc, curr) => {
            acc[curr.id] = 0
            return acc
        }, {})
    let [pricingNeedDeltas, setPricingNeedDeltas] = useState<PricingNeedDeltasBySiteId>(initPricingNeedDeltas())
    let vessel = VesselEditContainer.useContainer()

    useEffect(() => {
        let deltas = initPricingNeedDeltas()
        for (var key in deltas)
            deltas[key] = pricingNeedDeltas[key] ?? 0

        setPricingNeedDeltas(deltas)
    }, [purchaseMovement.sites])

    let shouldHideVesselPricing = !hasFeature('VesselAutoCreateDeal')
    let hasMultiplePricingPeriods = hasFeature('VesselMultiplePricingPeriod') && !isFixedPrice
    let hasMultiplePremiums = hasFeature('VesselMultiplePremium') && !isFixedPrice
    let hasExpositionSimulation = hasMultiplePricingPeriods && hasClaim(Claims.MarketRiskSimulatorReader)

    let sites = (purchaseMvt: PurchaseMovement): Item<string>[] => {
        if (!vessel.movementStock || !vessel.sites)
            return []

        let purchaseMovementStockSites = vessel.movementStock
            .filter(x => x.company === purchaseMvt.companyCode
                && x.dutyStatus === purchaseMvt.dutyStatus
                && x.productId === vesselProduct.productId)
            .map(x => x.site)
        return vessel.sites
            .filter(x => purchaseMovementStockSites.includes(x.code))
            .map(x => ({ value: x.code, text: x.name }))
    }

    let changeDelta = (siteId: Guid, oldValue: number | null, newValue: number | null) => {
        let deltas = pricingNeedDeltas
        deltas[siteId] = (newValue ?? 0) - ((oldValue ?? 0) - (pricingNeedDeltas[siteId] ?? 0))
        setPricingNeedDeltas(deltas)
    }

    let updateQuantity = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        if (vesselProduct.productUnit == 'TO')
            changeDelta(site.id, site.quantity, newValue)
        updateSite(purchaseMvt, site, site => { return { ...site, quantity: newValue, quantityOverride: newValue != null } })
    }

    let updateVolume = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        if (vesselProduct.productUnit != 'TO')
            changeDelta(site.id, site.volume, newValue)
        updateSite(purchaseMvt, site, site => { return { ...site, volume: newValue, volumeOverride: newValue != null } })
    }

    let updateContractualDensity = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        updateSite(purchaseMvt, site, site => {
            return {
                ...site, contractualDensity: newValue,
                contractualDensityOverwritten: newValue != null
            }
        })
    }

    let updateSite = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, update: (site: SitePurchaseMovement) => SitePurchaseMovement) => {
        let siteIndex = purchaseMvt.sites.findIndex(x => x.id === site.id)
        purchaseMvt.sites[siteIndex] = update(site)
        vessel.setPurchaseMovement(vesselProduct.id, purchaseMvt)
    }

    let handleDeleteSite = (site: SitePurchaseMovement) => {
        let isLastSite = purchaseMovement.sites.length == 1
        if (isLastSite)
            setPurchaseMovementToDelete()
        else {
            setSitePurchaseMovementToDelete(site)
        }
    }

    let handleAssignDeal = async (site: SitePurchaseMovement | null, purchaseMovement: PurchaseMovement | null) => {
        await vessel.openAssignableDeals(site?.movementId!)
        vessel.setSitePurchaseMovementWithAssignedDeal({ purchaseMovementId: purchaseMovement?.id!, sitePurchaseMovementId: site?.id!, productId: vesselProduct.id })
    }

    let shouldDisplayDelta = (siteId: string): boolean =>
        siteId in pricingNeedDeltas
        && siteId in vessel.pricingNeeds
        && vessel.pricingNeeds[siteId] !== null
    let isVolume = vesselProduct.productUnit !== 'TO'

    return (
        <Table style={{ tableLayout: 'auto' }} size='small'>
            <TableHead>
                <TableRow>
                    <Cell>{t(tBase + 'site')}</Cell>
                    {hasFeature('PurchaseMovementTransporter') && <Cell>{t(tBase + 'transporter')}</Cell>}
                    <Cell>{t(tBase + 'availabilityDate')}</Cell>
                    <Cell>{t(tBase + 'quantityUnit')}</Cell>
                    {isVolume && <Cell>{t(tBase + 'volumeUnit', { unit: vesselProduct.productUnit })}</Cell>}
                    {!shouldHideVesselPricing && <>
                        {hasMultiplePricingPeriods && <Cell>{t(tBase + 'pricingFrom')}</Cell>}
                        {hasMultiplePricingPeriods && <Cell>{t(tBase + 'pricingTo')}</Cell>}
                        {hasMultiplePremiums && <Cell>{t(tBase + 'premium', { unit: vesselProduct.premiumCurrency })}</Cell>}
                    </>}
                    {vessel.isAuthorized('aseReference') && <Cell>{t(tBase + 'aseReference')}</Cell>}
                    {hasExpositionSimulation && <Cell>{t(tBase + 'simulationExposition')}</Cell>}
                    {hasFeature('PricingEscalation') && <Cell>{t(tBase + 'contractualDensity', { unit: vesselProduct.productUnit })}</Cell>}
                    {hasFeature('PurchaseMovementTransporter') && <Cell>{t(tBase + 'purchaseOrder')}</Cell>}
                    <Cell></Cell>
                </TableRow>
            </TableHead>
            <TableBody>
                {purchaseMovement.sites.map((site, i) =>
                    (site.siteCode ?? sites(purchaseMovement)[0]?.value) &&
                    <TableRow className={classes.sitePurchaseMvtRow} key={i}>
                        <Cell key={'siteCode'}>
                            <Select
                                disableNewStyle
                                classesOverride={{ form: classes.select, select: 'site-select' }}
                                value={site.siteCode ?? sites(purchaseMovement)[0]?.value}
                                choices={sites(purchaseMovement)}
                                onChange={val =>
                                    updateSite(purchaseMovement, site, s => { return { ...s, siteCode: val } })} />
                        </Cell>
                        {hasFeature('PurchaseMovementTransporter') && <Cell key={'transporter'}>
                            <Select
                                disableNewStyle
                                classesOverride={{ form: classes.select }}
                                value={site.transporterId}
                                choices={vessel.transporters[purchaseMovement.companyCode].map(x => { return { value: x.id, text: x.name } })}
                                onChange={val =>
                                    updateSite(purchaseMovement, site, s => { return { ...s, transporterId: val } })} />
                        </Cell>}
                        <Cell key={'availabilityDate'}>
                            <DatePicker classesOverride={{ datepicker: classes.dateInput }}
                                disableNewStyle
                                date={site.availabilityDate}
                                auto={!site.availabilityDateOverwritten}
                                placeholder={vessel.state.availabilityDate ?? undefined}
                                label={''}
                                setDate={(newDate: string | null) =>
                                    updateSite(purchaseMovement, site, s => {
                                        return { ...s, availabilityDate: newDate, availabilityDateOverwritten: newDate != null }
                                    })}
                            />
                        </Cell>
                        <Cell key={'quantity'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateQuantity(purchaseMovement, site, vol)}
                                text={site.quantity}
                                precision={3} auto={!site.quantityOverride} />
                        </Cell>
                        {isVolume && <Cell key={'volume'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateVolume(purchaseMovement, site, vol)}
                                text={site.volume}
                                precision={3} auto={!site.volumeOverride} />
                        </Cell>}
                        {!shouldHideVesselPricing && <>
                            {hasMultiplePricingPeriods ?
                                <Cell key={'pricingFrom'}>
                                    <DatePicker classesOverride={{ datepicker: classes.dateInput }}
                                        disableNewStyle
                                        date={site.pricingFrom}
                                        label={''}
                                        setDate={(newDate: string | null) =>
                                            updateSite(purchaseMovement, site, s => {
                                                return { ...s, pricingFrom: newDate }
                                            })}
                                    />
                                </Cell>
                                : undefined}
                            {hasMultiplePricingPeriods ?
                                <Cell key={'pricingTo'}>
                                    <DatePicker classesOverride={{ datepicker: classes.dateInput }}
                                        disableNewStyle
                                        date={site.pricingTo}
                                        label={''}
                                        setDate={(newDate: string | null) =>
                                            updateSite(purchaseMovement, site, s => {
                                                return { ...s, pricingTo: newDate }
                                            })}
                                    />
                                </Cell>
                                : undefined}
                            {hasMultiplePremiums ?
                                <Cell key={'premium'}>
                                    <NumberField disableNewStyle
                                        key={site.id} label={''}
                                        overrideStyle={{ root: classes.siteInput }}
                                        onChange={vol => updateSite(purchaseMovement, site, s => { return { ...s, premium: vol } })}
                                        text={site.premium}
                                        precision={3}
                                        allowNegative={true} />
                                </Cell>
                                : undefined}
                        </>}
                        {vessel.isAuthorized('aseReference') && <Cell key={'aseReference'}>
                            <TextField disableNewStyle
                                label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={e =>
                                    updateSite(purchaseMovement, site, site => { return { ...site, aseReference: e.target.value } })}
                                text={site.aseReference} />
                        </Cell>}
                        {hasExpositionSimulation &&
                            <Cell key={'simulationExposition'}>{
                                shouldDisplayDelta(site.id)
                                    ? <DeltaNumberLabel
                                        value={vessel.pricingNeeds[site.id]!}
                                        delta={pricingNeedDeltas[site.id]}
                                        align={'left'} />
                                    : <span>{t(tBase + 'noPricingNeedDelta')}</span>
                            }
                            </Cell>}
                        {hasFeature('PricingEscalation') && <Cell key={'contractualDensity'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={value => updateContractualDensity(purchaseMovement, site, value)}
                                text={site.contractualDensity}
                                precision={3} auto={!site.contractualDensityOverwritten} />
                        </Cell>}
                        {hasFeature('PurchaseMovementTransporter') && <Cell key={'purchaseOrder'}>
                            <TextField disableNewStyle label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={e =>
                                    updateSite(purchaseMovement, site, site => { return { ...site, purchaseOrder: e.target.value } })}
                                text={site.purchaseOrder} />
                        </Cell>}
                        <RightAlignedCell key={'buttons'}>
                            <div>
                                {!!site.dealId
                                    ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToDeal', { reference: site.dealReferenceNumber })}</Typography>} placement='top'>
                                        <IconButton className={classes.iconButton} aria-label='go to deal' size={'small'}
                                            onClick={() => popupNavigator.open('deal', site.dealId)}>
                                            <GavelOutlined className='site-open-deal-icon' />
                                        </IconButton>
                                    </Tooltip>
                                    : (!!site.movementId && !hasFeature('VesselAutoCreateDeal'))
                                        ? <IconButton className={classes.iconButton} aria-label='assign deal' size={'small'}
                                            onClick={() => handleAssignDeal(site, purchaseMovement)}>
                                            <Badge badgeContent={<Link />}><GavelOutlined className='site-open-deal-icon' /></Badge>
                                        </IconButton>
                                        : undefined}
                                {!!site.movementId
                                    ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToMovement')}</Typography>} placement='top'>
                                        <IconButton className={classes.iconButton} aria-label='go to movement' size={'small'}
                                            onClick={() => popupNavigator.open('movement', site.movementId)}>
                                            <TrendingFlat className='site-open-movement-icon' />
                                        </IconButton>
                                    </Tooltip>
                                    : undefined}
                                {!isFixedPrice
                                    ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'duplicate')}</Typography>} placement='top'>
                                        <IconButton className={classes.iconButton} aria-label='duplicate' size={'small'}
                                            onClick={() => addSite(purchaseMovement, duplicateSite(site))}>
                                            <FileCopyOutlined />
                                        </IconButton>
                                    </Tooltip>
                                    : undefined
                                }
                                <IconButton onClick={() => handleDeleteSite(site)} size={'small'}><DeleteOutlined /></IconButton>
                            </div>
                        </RightAlignedCell>
                    </TableRow>)}
            </TableBody>
        </Table>)
}

export let PricePurchaseMovementTable = withStyles(commonStyle, muiOptions)(_PricePurchaseMovementTable)