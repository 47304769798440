import { Chip, createStyles, withStyles } from "@material-ui/core"
import React from "react"
import { defaultColors, muiOptions, MuiProps } from "../../../infrastructure/materialUiThemeProvider"

type SapChipProps = {
    label: string | null
    isFromSap: boolean | null
    hasValue: boolean | null
    className: 'dealList' | 'movementList' | 'default'
    key?: number
    disabled?: boolean
    clickable?: boolean
    onClick?: (event: any) => any
}

function _SapChip(
    { classes, label, isFromSap, hasValue, className, key, disabled, clickable, onClick }
        : MuiProps & SapChipProps) {
    return (
        <Chip
            key={key}
            className={[
                className,
                classes.sapChip,
                hasValue ? (isFromSap ? 'isFromSap' : 'hasValue') : ''
            ].join(' ')}
            label={label}
            disabled={disabled}
            clickable={clickable}
            onClick={onClick}
        />
    );
}

const commonChipStyles = {
    minWidth: '2.5em',
    height: '2.5em',
    color: defaultColors.grey.main.color
}

const styles = _ => createStyles({
    sapChip: {
        borderRadius: '7px',
        marginLeft: '0.2em',
        marginRight: '0.2em',
        '&.dealList': {
            ...commonChipStyles,
            '& span': {
                textOverflow: 'clip !important',
                padding: '0 !important'
            },
        },
        '&.movementList': {
            ...commonChipStyles,
            '& span': {
                padding: '0px !important'
            }
        },
        '&.isFromSap': {
            backgroundColor: '#ddefdd',
            color: defaultColors.green.main.color,
        },
        '&.hasValue': {
            backgroundColor: '#daecf9',
            color: defaultColors.darkBlue.main.color,
        },
    }
})

export let SapChip = withStyles(styles, muiOptions)(_SapChip)