import { muiOptions } from '../../../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import { TruckTransportContainer } from '../../truckStore'
import { DatePicker, MultipleSelect, GroupedMultiSelect, Switch, DateRangePicker } from '../../../common/customComponents'

function _TruckFilter({ classes }) {
    let store = TruckTransportContainer.useContainer()
    let t = store.t
    let filters = store.filters
    let referential = store.referential

    useEffect(() => {
        store.referential.load()
    }, [])

    useEffect(() => {
        store.loadTable()
    }, [filters.fields])

    return (
        <Paper className={classes.paperFilter}>
            <Switch form isChecked={store.filters.fields.inTransit}
                changeCallback={() => store.filters.toggleOnGoing()}
                offText={t('trucks.filters.allTrucks')}
                onText={t('trucks.filters.onGoing')} />
            {!filters.fields.inTransit ?
                <DateRangePicker startDate={filters.fields?.startPeriod || null} startLabel={t('trucks.filters.startDate')}
                    endDate={filters.fields?.endPeriod || null} endLabel={t('trucks.filters.endDate')}
                    onPeriodChange={period => filters.changePeriod(period.startDate, period.endDate)}
                    classes={classes} disableNewStyle onError={filters.filterDatesOnError}
                    classesOverride={{ datepicker: classes.filterFieldDate }} />
                : <DatePicker date={filters.fields?.inTransitAt || null} label={t('trucks.filters.inTransitAt')}
                    classesOverride={{ datepicker: classes.filterFieldDate }}
                    setDate={newDate => filters.changeDate(newDate, 'inTransitAt')} classes={classes} disableNewStyle />
            }
            {referential.products.every(x => !x.productGroup)
                ? <MultipleSelect label={t('trucks.filters.products')} classesOverride={{ form: classes.filterField }}
                    values={referential.products.filter(x => !!filters.fields.productIds && filters.fields.productIds.indexOf(x.id) > -1).map(x => x.id)}
                    choices={referential.products.map(x => ({ value: x.id, text: x.code }))} disableNewStyle
                    allWhenEmpty={true} hideOnSingleChoice
                    onChange={x => filters.setFields({ ...filters.fields, productIds: x })} />
                : <GroupedMultiSelect label={t('trucks.filters.products')} classesOverride={{ form: classes.filterField }}
                    values={filters.fields.productIds ?? []} hideOnSingleChoice allWhenEmpty
                    choices={referential.products.map(x => ({ value: x.id, text: x.code, group: x.productGroup ?? x.code }))} disableNewStyle
                    onChange={val => filters.setFields({ ...filters.fields, productIds: val })} />
            }
            <GroupedMultiSelect
                label={t('trucks.filters.originSites')}
                classesOverride={{ form: classes.filterField }}
                values={filters.fields.originSites ?? []}
                choices={referential.sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup! }))}
                allWhenEmpty={true} disableNewStyle
                onChange={x => filters.setFields({ ...filters.fields, originSites: x })} />
            <GroupedMultiSelect
                label={t('trucks.filters.destinationSites')}
                classesOverride={{ form: classes.filterField }}
                values={filters.fields.destinationSites ?? []}
                choices={referential.sites.map(x => ({ value: x.code, text: x.name, group: x.siteGroup! }))}
                allWhenEmpty={true} disableNewStyle
                onChange={x => filters.setFields({ ...filters.fields, destinationSites: x })} />
            <MultipleSelect label={t('trucks.filters.customerSegments')} classesOverride={{ form: classes.filterField }}
                values={referential.customerSegments.filter(x => !!filters.fields.customerSegments && filters.fields.customerSegments.indexOf(x) > -1)}
                choices={referential.customerSegments.map(x => ({ value: x, text: x }))}
                allWhenEmpty={true} hideOnSingleChoice disableNewStyle
                onChange={x => filters.setFields({ ...filters.fields, customerSegments: x })} />
        </Paper>
    )
}

let styles = theme =>
    createStyles({
        paperFilter: {
            height: 'auto',
            padding: '0.8em',
            display: 'flex'
        },
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
    })

export let TruckFilter = withStyles(styles, muiOptions)(_TruckFilter)