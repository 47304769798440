import React, { useState } from 'react'
import { MenuItem, Select as MuiSelect, Checkbox, ListItemText } from '@material-ui/core'
import { t } from '../../../../infrastructure/i18nextHelper'
import { CustomSelectWrapper, CustomSelectProps } from './customSelectWrapper'
import { CustomSelectStyleProps, parseClassNames } from './select'
import { Item } from './types'

type GroupedMultiSelectProps = {
    values: string[] | string | null | undefined
    choices: Item<string>[]
    disableNewStyle?: boolean
    allWhenEmpty?: boolean
    singleChoice?: boolean
    onChange: (values: string[] | string) => void
    disabled?: boolean
} & CustomSelectProps

export function GroupedMultiSelect(props: GroupedMultiSelectProps & CustomSelectStyleProps) {
    let [isEmpty, setIsEmpty] = useState<boolean>(false)
    const separator = '♣'

    let groups: [string, Item<string>[]][] =
        Object.entries(props.choices.indexMultipleValueByProp('group')).sort((p1, p2) => p1[0] > p2[0] ? 1 : -1)

    let values: string | string[] = props.singleChoice
        ? (Array.isArray(props.values) ? props.values[0] : props.values) ?? ''
        : (!isEmpty && props.values && Array.isArray(props.values) ? props.values.filter(x => props.choices.some(s => s.value === x)) : [])
    let groupsFilled = groups.filter(x => x[1].every(y => values.indexOf(y.value) > -1))

    let variant = !props.disableNewStyle ? { variant: 'filled' } as any : {}

    return (
        <CustomSelectWrapper label={props.label} classNames={parseClassNames({ ...props })}>
            <MuiSelect
                multiple={!props.singleChoice}
                id={props.id ?? ''}
                {...variant}
                className={props.classesOverride?.select ?? props.classes.defaultSelect}
                value={values}
                displayEmpty={true}
                disabled={props.disabled}
                MenuProps={{ className: props.classes.noDrag }}
                renderValue={x => replaceByGroups(x as string[] | string)}
                onChange={e => props.singleChoice ? onChange(e.target.value as string) : onChange(e.target.value as string[])}>
                {groups.map((choicesByGroup, index) => {
                    let [group, choices] = choicesByGroup
                    return GroupLine(group, choices, index, !props.singleChoice)
                }).flat()}
            </MuiSelect>
        </CustomSelectWrapper>
    )

    function GroupLine(group: string, choices: Item<string>[], key: number, multiple: boolean): JSX.Element[] {
        let result: JSX.Element[] = []
        let isSingleChoice = choices.length === 1 && choices[0].text === group

        if (!isSingleChoice) {
            let groupLine =
                <MenuItem disabled={!multiple} className={props.classes.itemWithCheckBox} key={group + key} value={getGroupValue(choices)}
                    classes={groupsFilled.some(x => x[0] === group) && multiple ? { root: props.classes.selected } : {}}>
                    {multiple && <Checkbox checked={choices.some(x => values.indexOf(x.value) > -1)} indeterminate={!choices.every(x => values.indexOf(x.value) > -1)} />}
                    <ListItemText primary={group} classes={props.classes ? { primary: props.classes?.selectGroup } : {}} />
                </MenuItem>

            let choiceLines = choices.map((choice, index) => (
                <MenuItem className={props.classes.menuItem} key={choice.value + index} value={choice.value}>
                    <span>     </span>{multiple && <Checkbox checked={!!(values?.indexOf(choice.value) > -1)} />}
                    <ListItemText primary={choice.text} />
                </MenuItem>))

            result = [groupLine, choiceLines].flat()
        }
        else {
            let singleLine =
                <MenuItem className={props.classes.itemWithCheckBox} key={group + key} value={choices[0].value}>
                    {multiple && <Checkbox checked={values?.indexOf(choices[0].value) > -1} />}
                    <ListItemText primary={group} classes={props.classes ? { primary: props.classes?.selectGroup } : {}} />
                </MenuItem>

            result = [singleLine]
        }

        return result
    }

    function replaceByGroups(selectedVals: string[] | string) {
        if (props.singleChoice) return props.choices.find(x => x.value === selectedVals as string)?.text

        let hasNoValue = !selectedVals || selectedVals.length === 0
        let hasAllValues = selectedVals.length === props.choices.length && props.choices.length > 2
        return hasNoValue || hasAllValues
            ? t('components.allFiltersLine')
            : (selectedVals as string[]).map(t => {
                let groupFound = groupsFilled.find(g => g[1].find(choice => choice.value === t))
                return groupFound && groupFound[1].length > 1 ? groupFound[0] : props.choices.find(x => x.value === t)?.text ?? t
            }).distinct().join(', ')
    }

    function getGroupValue(choices) { return choices.length > 1 ? choices.map(x => x.value).join(separator) : choices[0].value + separator }

    function onChange(selectedVals: string[] | string) {
        if (props.singleChoice) {
            props.onChange(selectedVals)
            return
        }
        let vals = (selectedVals as string[])?.map(x => x.split(separator)).flat().filter(x => x != '').distinct()
        let groupClicked = (selectedVals as string[]).find(x => x.contains(separator))?.split(separator).filter(x => x != '')
        if (groupClicked && groupClicked.every(x => (values ?? []).indexOf(x) > -1)) {
            vals = vals.filter(x => groupClicked!.indexOf(x) === -1)
        }

        if (vals.length === 0) {
            setIsEmpty(true)
            if (props.allWhenEmpty)
                vals = props.choices.map(x => x.value)
        }
        else if (isEmpty && vals.length > 0)
            setIsEmpty(false)

        props.onChange(vals)
    }
}