import React from 'react'
import { useFieldStatus, alertStatus } from '../../common/fieldsStatus'
import { Vessel, VesselProduct } from '../vesselModels'
import { hasFeature } from '../../../infrastructure/feature'

export function useVesselFormErrors(context: StoreContext) {
    let store = React.useContext(context)

    let vesselFieldStatus = useFieldStatus<Vessel>()
    let vesselProductFieldStatus = useFieldStatus<VesselProduct>()

    let { vesselState } = store

    function highlightUpdateErrors() {
        vesselFieldStatus.clearStatuses()
        if (!vesselState?.jettyCode)
            vesselFieldStatus.setStatus('jettyCode', alertStatus)
        if (!vesselState?.originalLaycanStartDate)
            vesselFieldStatus.setStatus('originalLaycanStartDate', alertStatus)
        if (!vesselState?.originalLaycanEndDate)
            vesselFieldStatus.setStatus('originalLaycanEndDate', alertStatus)
    }

    function highlightSettingActualStatusErrors() {
        vesselProductFieldStatus.clearStatuses()
        vesselFieldStatus.clearStatuses()
        if (!vesselState?.counterpartySupplierId)
            vesselFieldStatus.setStatus('counterpartySupplierId', alertStatus)
        if (!!vesselState?.products)
            for (let vesselProduct of vesselState?.products)
                if (!vesselProduct?.loadPort && hasFeature("LoadPortCompulsoryWhenActual")) {
                    vesselProductFieldStatus.setStatus('loadPort', alertStatus)
                    break
                }
    }
    return { highlightUpdateErrors, vesselFieldStatus, vesselProductFieldStatus, highlightSettingActualStatusErrors }
}

export type StoreContext = React.Context<StoreDependencies>

type StoreDependencies = {
    vesselState: Vessel
}