import React, { useState, useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, Select, TableItem } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'
import { LocalProduct } from '../masterData/localProduct'
import { applyFilters } from '../../common/filtersHelper'
import { Item } from '../../common/components/select/types'

function PricingPeriodMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [quotations, setQuotations] = useState<Quotation[]>([])
    let [products, setProducts] = useState<ProductChannels[]>([])
    let [pricingPeriods, setPricingPeriods] = useState<TableItem<PricingPeriod>[]>([])
    let [filters, setFilters] = useState<PricingPeriodFilters>(noFilters)
    let [localProducts, setLocalProducts] = useState<LocalProduct[]>([])

    const productChannels = (productId: Guid) => products.find(x => x.productId === productId)?.channels ?? []
    const channelCustomerSegments = (productId: Guid, channel: string) =>
        products.find(x => x.productId === productId)?.channels?.find(x => x.name === channel)?.customerSegments ?? []

    const tBase = 'admin.masterdata.pricingPeriod.'
    const movementDates: Item<MovementDate>[] =
        ['FirstFortnight', 'SecondFortnight', 'FullMonth', 'Current15ToNext14', 'RefBy4Weeks']
            .map(x => { return { value: x as MovementDate, text: t(`${tBase}movementDate.${x}`) } })
    const pricingFormulas: Item<PricingFormula>[] =
        ['Last13ToLast27', 'Last28To12', 'FullMonth', 'LastFullMonth', 'SecondLastFullMonth', 'SecondLast25ToLast24', 'RefBy4Weeks']
            .map(x => { return { value: x as PricingFormula, text: t(`${tBase}pricingFormula.${x}`) } })

    let load = async () => {
        let quotationPromise = api.get<Quotation[]>('masterdata/pricingPeriod/priceIndex')
        let productPromise = api.get<ProductChannels[]>('masterdata/pricingPeriod/productChannelCustomerSegment')
        let pricingPeriodPromise = api.get<TableItem<PricingPeriod>[]>('masterdata/pricingPeriod')
        let localProductPromise = api.get<LocalProduct[]>('masterdata/pricingPeriod/localProduct')

        setPricingPeriods(await pricingPeriodPromise)
        setQuotations(await quotationPromise)
        setProducts(await productPromise)
        setLocalProducts(await localProductPromise)
    }

    let getPriceIndexs = (productId: string) => {
        let productPriceIndexs = localProducts.find(x => x.productId == productId)?.quotations
        return quotations.filter(x => productPriceIndexs?.find(i => i == x.medecoCode))
    }

    let getItems = () => applyFilters(pricingPeriods, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: PricingPeriod) => {
        await api.post('masterdata/pricingPeriod', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        if (ids.length === 0) return false
        await api.del('masterdata/pricingPeriod', { ids })
        await load()
        return true
    }

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'PricingPeriod.xlsx',
            sheets: [{ name: 'PricingPeriod', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<PricingPeriod>>>[] = [
        {
            name: t(tBase + 'product'),
            value: x => x.product,
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product }) }
        },
        {
            name: t(tBase + 'channel'),
            value: x => x.channel,
            columnFilter: { value: filters.channel ?? '', onChange: (channel: string) => setFilters({ ...filters, channel }) }
        },
        {
            name: t(tBase + 'customerSegment'),
            value: x => x.customerSegment,
            columnFilter: {
                value: filters.customerSegment ?? '',
                onChange: (customerSegment: string) => setFilters({ ...filters, customerSegment })
            }
        },
        {
            name: t(tBase + 'finalPricingFormulaIndex'),
            value: x => x.finalPricingFormulaIndexName,
            columnFilter: {
                value: filters.finalPricingFormulaIndexName ?? '',
                onChange: (finalPricingFormulaIndexName: string) => setFilters({ ...filters, finalPricingFormulaIndexName })
            }
        },
        {
            name: t(tBase + 'movementDate.label'),
            value: x => t(`${tBase}movementDate.${x.movementDate}`).toString()
        },
        {
            name: t(tBase + 'pricingFormula.label'),
            value: x => t(`${tBase}pricingFormula.${x.pricingFormula}`).toString()
        }
    ]

    let isManager = hasClaim(Claims.MasterdataPricingPeriodManager)

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'pricing-period-table'}
                headerLabel={t(tBase + 'marketingSales')}
                itemLabel={t(tBase + 'marketingSale')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onNew={emptyPricingPeriod}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t(tBase + 'product')}
                                disabled={!isManager}
                                value={selectedItem.productId}
                                choices={products.map(x => ({ value: x.productId, text: x.code }))}
                                onChange={productId => {
                                    if (!productId) return
                                    const channel = productChannels(productId).find(x => x === selectedItem.channel)?.name
                                        || productChannels(productId)[0].name
                                    let priceIndex = getPriceIndexs(productId).find(x => x.medecoCode === selectedItem.finalPricingFormulaIndex)?.medecoCode
                                        || getPriceIndexs(productId)[0]?.medecoCode
                                    setSelectedItem({ ...selectedItem, productId, channel, finalPricingFormulaIndex: priceIndex })
                                }} />
                            <Select label={t(tBase + 'channel')}
                                disabled={!isManager}
                                value={selectedItem.channel}
                                choices={productChannels(selectedItem.productId).map(x => ({ value: x.name, text: x.name }))}
                                onChange={channel => {
                                    if (!channel) return
                                    setSelectedItem({ ...selectedItem, channel, customerSegment: null })
                                }} />
                            <Select label={t(tBase + 'customerSegment')}
                                isClearable={true}
                                clearableLabel='None'
                                disabled={!isManager}
                                value={selectedItem.customerSegment}
                                choices={channelCustomerSegments(selectedItem.productId, selectedItem.channel)
                                    .map(x => ({ value: x, text: x }))}
                                onChange={customerSegment => {
                                    setSelectedItem({ ...selectedItem, customerSegment })
                                }} />
                            <Select label={t(tBase + 'finalPricingFormulaIndex')}
                                disabled={!isManager}
                                value={selectedItem.finalPricingFormulaIndex}
                                choices={getPriceIndexs(selectedItem.productId).map(x => ({ value: x.medecoCode, text: x.name }))}
                                onChange={finalPricingFormulaIndex => {
                                    if (finalPricingFormulaIndex) setSelectedItem({ ...selectedItem, finalPricingFormulaIndex })
                                }} />
                            <Select label={t(tBase + 'movementDate.label')}
                                disabled={!isManager}
                                value={selectedItem.movementDate}
                                choices={movementDates}
                                onChange={movementDate => { if (movementDate) setSelectedItem({ ...selectedItem, movementDate }) }} />
                            <Select label={t(tBase + 'pricingFormula.label')}
                                disabled={!isManager}
                                value={selectedItem.pricingFormula}
                                choices={pricingFormulas}
                                onChange={pricingFormula => { if (pricingFormula) setSelectedItem({ ...selectedItem, pricingFormula }) }} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

type PricingPeriod = {
    id: Guid,
    productId: Guid,
    product: string,
    channel: string,
    finalPricingFormulaIndex: string,
    finalPricingFormulaIndexName: string,
    movementDate: MovementDate,
    pricingFormula: PricingFormula,
    customerSegment: string | null
}

type Quotation = {
    medecoCode: string
    name: string
    longName: string
    unitOfMeasurement: string
}

type MovementDate = 'FirstFortnight' | 'SecondFortnight' | 'FullMonth' | 'Current15ToNext14' | 'RefBy4Weeks'

type PricingFormula = 'Last13ToLast27' | 'Last28To12' | 'FullMonth' | 'LastFullMonth' | 'SecondLastFullMonth' | 'SecondLast25ToLast24' | 'RefBy4Weeks'

type ProductChannels = {
    productId: Guid,
    code: string
    channels: Channel[]
}

type Channel = {
    name: string
    customerSegments: string[]
}

let emptyPricingPeriod = (): PricingPeriod => ({
    id: guid.empty,
    productId: guid.empty,
    product: '',
    channel: '',
    finalPricingFormulaIndex: '',
    finalPricingFormulaIndexName: '',
    movementDate: 'FirstFortnight',
    pricingFormula: 'Last13ToLast27',
    customerSegment: ''
})

let toTableItem = (pricingPeriod: PricingPeriod): TableItem<MasterDataItem<PricingPeriod>> => {
    return {
        id: pricingPeriod.id,
        productId: pricingPeriod.productId,
        product: pricingPeriod.product,
        channel: pricingPeriod.channel,
        finalPricingFormulaIndex: pricingPeriod.finalPricingFormulaIndex,
        finalPricingFormulaIndexName: pricingPeriod.finalPricingFormulaIndexName,
        movementDate: pricingPeriod.movementDate,
        pricingFormula: pricingPeriod.pricingFormula,
        isModified: false,
        customerSegment: pricingPeriod.customerSegment
    }
}

type PricingPeriodFilters = {
    product: string | null,
    channel: string | null,
    finalPricingFormulaIndexName: string | null,
    customerSegment: string | null
}

let noFilters: PricingPeriodFilters = {
    product: null,
    channel: null,
    finalPricingFormulaIndexName: null,
    customerSegment: null
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        inStockProjectionContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        },
    })

export default withStyles(styles, muiOptions)(PricingPeriodMasterData)