import React, { useEffect } from 'react'
import { createStyles, withStyles, Typography, Tooltip } from '@material-ui/core'
import { defaultStyles, muiOptions, defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { hasFeature } from '../../../infrastructure/feature'
import { t } from '../../../infrastructure/i18nextHelper'
import { TextField, Select, DatePicker, ToVolumeFactorField, NumberField, Switch } from '../../common/customComponents'
import { DealContainer } from './dealEditStore'
import { DealMovementType } from '../dealModels'
import * as Component from './_dealComponents'
import { CommentButton } from '../../common/comment/commentButton'
import { CommentContent } from '../../common/comment/commentContent'
import { Item } from '../../common/components/select/types'

const tPrefix = 'deals.label.'

function _TitleSection({ classes }) {

    let store = DealContainer.useContainer()

    if (!store?.deal) return (<div />)

    let dealTitle = () => {
        let title: string = !store?.deal?.movementType
            ? t('deals.popup.generalPartTitle')
            : store.getTranslatedMovementType(store?.deal?.movementType)
        return title + ' ' + t('deals.popup.nameDeal')
    }

    return (
        <div className={classes.rowFlexStart}>
            <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                {dealTitle()}
            </Typography>
        </div>
    )
}

function _ReferenceNumberSection({ classes }) {
    let store = DealContainer.useContainer()

    let dealTypesValues = store.dealTypes.map(x => ({ value: x, text: x }))
    let movementTypesValues: Item<string>[] =
        Object.keys(DealMovementType).map(key => ({ value: DealMovementType[key], text: key }))

    if (!store?.deal) return (<div />)
    let deal = store.deal
    let dutyStatusLocked = !deal.dutyStatus && (store.locked() || !store.dutyStatuses || !store.dutyStatuses.length)
    let disabledForLinkedOrAutonomous = store.locked() || store.isAssociatedToAMovement() || store.isAutonomous()
    let disabledWhenLinkedToVessel = store.locked() && !hasFeature('VesselAutoCreateDeal')
    let isCompanyDisabled = disabledForLinkedOrAutonomous || !!store.deal.mirrorDealId || store.isDuplicate() || store.isAssociatedToAMovement() || store.isInternal()
    let getCounterpartyName = (id: string) => {
        if (store.allCounterpartys.length === 0)
            return ''
        return store.allCounterpartys.find(x => x.id === id)?.name
    }

    return (
        <div className={classes.rowFlexStart}>
            <TextField label={t(tPrefix + 'referenceNumber')}
                disabled={disabledWhenLinkedToVessel || store.isInternal()}
                text={deal.referenceNumber}
                status={store.fieldStatus.getStatus('referenceNumber')}
                onChange={event => store.setDeal({ ...deal, referenceNumber: event.target.value })} />
            {(store.isCreating() || store.isAutonomous()) &&
                <Select label={t(tPrefix + 'movementType')}
                    disabled={store.isAutonomous() || store.isDuplicate() || store.isInternal()}
                    value={deal.movementType}
                    choices={movementTypesValues}
                    status={store.fieldStatus.getStatus('movementType')}
                    onChange={(val) => store.setDeal({ ...deal, movementType: val })} />}
            {store.isInternal()
                ? <TextField label={t(tPrefix + 'type')}
                    disabled={true}
                    text={deal.dealType} />
                : <Select label={t(tPrefix + 'type')}
                    disabled={store.isDuplicate()}
                    value={deal.dealType}
                    choices={dealTypesValues}
                    status={store.fieldStatus.getStatus('dealType')}
                    auto={!deal.dealTypeOverwritten}
                    onChange={val => { if (val) store.setDeal({ ...deal, dealType: val, dealTypeOverwritten: true }) }} />}
            {store.isInternal()
                ? <TextField label={t(tPrefix + 'counterparty')}
                    disabled={true}
                    text={getCounterpartyName(deal.counterpartyId!)} />
                : <Select label={t(tPrefix + 'counterparty')}
                    disabled={disabledWhenLinkedToVessel || !!store.deal.mirrorDealId || store.deal.generateMktSaleForecasts}
                    value={deal.counterpartyId ?? ''}
                    status={store.fieldStatus.getStatus('counterpartyId')}
                    choices={store.counterpartys.map(x => ({ value: x.id, text: x.name }))}
                    onChange={val => { if (val) store.setDeal({ ...deal, counterpartyId: val }) }} />}
            <Component.ValidityPeriod />
            <Select label={t(tPrefix + 'company')}
                disabled={isCompanyDisabled}
                status={store.fieldStatus.getStatus('company')} value={deal.company}
                choices={store.companys.map(x => ({ value: x.code, text: x.name }))}
                onChange={val => { if (val) store.updateDealCompany(val) }} />
            <Component.Mot />
            <Component.Assignee />
            {hasFeature('PurchaseMovementTransporter') && store.associatedVesselId &&
                <TextField label={t(tPrefix + 'transporter')} disabled={true}
                    text={store.counterpartys.find(x => x.id == deal.transporterId)?.name ?? ""} />}
        </div>
    )
}

function _SiteSection({ classes }) {
    let store = DealContainer.useContainer()

    if (!store || !store.deal) return (<div />)
    let deal = store.deal
    let disabledForLinkedOrAutonomous = store.locked() || store.isAssociatedToAMovement() || store.isAutonomous()
    let dutyStatusLocked = !deal.dutyStatus && (store.locked() || !store.dutyStatuses || !store.dutyStatuses.length || store.isInternal())

    let handleProductChange = (val) => {
        store.setDeal({ ...deal, productId: val })
    }

    return (
        <div className={classes.rowFlexStart}>
            <Select label={t(tPrefix + 'site')}
                disabled={store.cannotEditProductAndSite() || store.isPaperTransaction()}
                value={!store.isPaperTransaction() ? deal.site : ''}
                status={store.fieldStatus.getStatus('site')}
                choices={store.sites.map(x => ({ value: x.code, text: x.name }))}
                onChange={val => { if (val) store.setDeal({ ...deal, site: val }) }} />
            <Select label={t(tPrefix + 'product')}
                disabled={store.cannotEditProductAndSite()}
                value={deal.productId ? deal.productId : ''}
                status={store.fieldStatus.getStatus('productId')}
                choices={store.products.map(x => ({ value: x.id, text: x.code }))}
                onChange={val => { if (val) handleProductChange(val) }} />
            <Component.DutyStatus isLocked={dutyStatusLocked} disabledForLinkedOrAutonomous={disabledForLinkedOrAutonomous} />
            <Select label={t(tPrefix + 'paymentTerms')}
                value={deal.paymentTerms ?? ''}
                disabled={store.isInternal()}
                status={store.fieldStatus.getStatus('paymentTerms')}
                auto={!deal.paymentTermsOverwritten && !!store.associatedVesselId}
                choices={store.paymentTerms.map(x => ({ value: x, text: t('referential.paymentTerms.' + x) }))}
                onChange={val => { if (val) store.setDeal({ ...deal, paymentTerms: val, paymentTermsOverwritten: true }) }} />
            <DatePicker date={deal.paymentTermsDate}
                status={store.fieldStatus.getStatus('paymentTermsDate')}
                label={t(tPrefix + 'paymentTermsDate')}
                disabled={store.locked() || store.isInternal()}
                setDate={newDate => { if (newDate) store.setDeal({ ...deal, paymentTermsDate: newDate }) }} />
            <Component.Currency />
            <NumberField
                label={t(tPrefix + 'dealNumber')}
                disabled={true}
                text={deal.dealNumber} />
        </div>
    )
}

function _VolumeSection({ classes }) {
    let store = DealContainer.useContainer()

    if (!store || !store.deal) return (<></>)
    let deal = store.deal

    return (
        <div className={classes.rowFlexSpaceBetween}>
            <div className={classes.rowFlexStart}>
                {store.unit !== 'TO' &&
                    <div>
                        <NumberField
                            label={t(tPrefix + 'volume', { unit: store.unit })}
                            disabled={store.locked() || store.isInternal()}
                            text={deal.volume}
                            status={store.fieldStatus.getStatus('volume')}
                            onChange={x => store.setDeal({ ...deal, volume: x, volumeOverwritten: x !== null })} />
                        <ToVolumeFactorField label={t(tPrefix + 'conversionFactor', { unit: store.unit })} text={deal.conversionFactor} />
                    </div>
                }
                <NumberField
                    label={t(tPrefix + 'quantity')}
                    disabled={store.locked() || store.isInternal()}
                    text={deal.quantity}
                    status={store.fieldStatus.getStatus('quantity')}
                    onChange={x => store.setDeal({ ...deal, quantity: x, quantityOverwritten: x !== null })} />
                {<NumberField
                    label={t(tPrefix + 'movementsVolume')}
                    disabled={true}
                    precision={3}
                    text={store.movementsVolume}
                />}
                <NumberField
                    label={t(tPrefix + 'sapQuantity')}
                    text={deal.sapQuantity}
                    auto={!deal.sapQuantityOverwritten}
                    disabled={store.locked() || store.isInternal()}
                    onChange={x => store.setDeal({ ...deal, sapQuantity: x, sapQuantityOverwritten: x !== null })} />
            </div>
        </div>
    )
}

function _TolerancesSection({ classes }) {

    let store = DealContainer.useContainer()

    if (!store || !store.deal) return (<></>)
    let deal = store.deal

    useEffect(() => {
        store.setDeal({ ...deal, quantityUnit: store.unit! })
    }, [store.unit])

    const buyerValue = 'buyer'
    const sellerValue = 'seller'
    const termValue = 'Term'
    const spotValue = 'Spot'

    useEffect(() => {
        if (!deal.toleranceOption)
            store.setDeal({ ...deal, toleranceOption: buyerValue })
    }, [])

    let handleQuantityUnitChange = (val: string) => {
        store.setDeal({
            ...deal,
            quantityUnit: val,
            quantityPriceUnit: getUnitPrice(store.quantityUnitPrices.find(x => x.quantity == val)?.unitPrice!)
        })
    }

    let quantityUnitChoices = (): Item<string>[] =>
        store.quantityUnitPrices
            .map(x => x.quantity)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(x => ({ value: x, text: x }))

    let handleQuantityUnitPriceChange = (val: string) => {
        store.setDeal({
            ...deal,
            quantityPriceUnit: val,
            currency: store.quantityUnitPrices.find(x => x.unitPrice === val)?.currency ?? store?.currency!
        })
    }

    let quantityUnitPriceChoices = (): Item<string>[] =>
        store.quantityUnitPrices
            .filter(x => x.quantity === deal.quantityUnit)
            .map(x =>
            ({
                value: getUnitPrice(x.unitPrice),
                text: getUnitPrice(x.unitPrice)
            }))
            .filter(x => !x.value.includes("CompanyCurrency"))

    let getUnitPrice = (unitPrice: string) => unitPrice.replace("CompanyCurrency", store?.currency ?? "CompanyCurrency")

    return (
        <div className={classes.rowFlexStart}>
            <NumberField
                label={t(tPrefix + 'underTolerance')}
                unit={'%'}
                text={deal.underTolerance}
                disabled={store.isInternal()}
                onChange={x => store.setDeal({ ...deal, underTolerance: x })}
            />
            <NumberField
                label={t(tPrefix + 'overTolerance')}
                unit={'%'}
                text={deal.overTolerance}
                disabled={store.isInternal()}
                onChange={x => store.setDeal({ ...deal, overTolerance: x })}
            />
            <Switch form
                isChecked={deal.toleranceOption === sellerValue}
                changeCallback={() => store.setDeal({ ...deal, toleranceOption: deal.toleranceOption === buyerValue ? sellerValue : buyerValue })}
                offText={t(tPrefix + buyerValue)}
                onText={t(tPrefix + sellerValue)}
                disabled={store.isInternal()} />
            {hasFeature('DealQuantityCurrencyUnitPrice') && (
                <>
                    <Select label={t(tPrefix + 'quantityUnit')}
                        disabled={store.isInternal()}
                        value={deal.quantityUnit}
                        status={store.fieldStatus.getStatus('quantityUnit')}
                        choices={quantityUnitChoices()}
                        onChange={val => { if (val) handleQuantityUnitChange(val) }} />
                    <Select label={t(tPrefix + 'quantityPriceUnit')}
                        value={deal.quantityPriceUnit}
                        status={store.fieldStatus.getStatus('quantityPriceUnit')}
                        choices={quantityUnitPriceChoices()}
                        onChange={val => { if (val) handleQuantityUnitPriceChange(val) }} />
                </>
            )}
            <Switch form
                isChecked={deal.termSpot === 'Spot'}
                offText={termValue}
                onText={spotValue}
                disabled={store.isInternal()}
                changeCallback={() => store.setDeal({ ...deal, termSpot: deal.termSpot === termValue ? spotValue : termValue })} />
            <Select label={t(tPrefix + 'frontOfficer')}
                value={deal?.frontOfficer}
                disabled={store.isInternal()}
                choices={store.assigneeChoices.map(x => ({ value: x.userName, text: x.fullName }))}
                onChange={val => (store.setDeal({ ...deal, frontOfficer: val }))} />
        </div>
    )
}

function _CommentSection({ classes }) {
    let store = DealContainer.useContainer()

    if (!store || !store.deal) return (<></>)
    let deal = store.deal
    let associationKey = `deal-${deal.id}`

    return (
        <div className={classes.commentRow}>
            <CommentContent associationKey={associationKey} />
            <CommentButton withPadding
                associationKey={associationKey}
                readOnly={store.isInternal()} />
        </div>
    )
}

let styles = _ =>
    createStyles({
        rowFlexStart: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '1em'
        },
        rowFlexSpaceBetween: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        commentRow: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: '1.5em 0 0.5em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginRight: '3em'
        },
        buttonToVessel: {
            ...defaultStyles.primaryButton,
            marginTop: '2em',
        },
        buttonMirrorDeal: {
            ...defaultStyles.secondaryButton,
            marginTop: '2em',
            marginLeft: '1em'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            margin: "1em"
        }
    })

export let TitleSection = withStyles(styles, muiOptions)(_TitleSection)
export let ReferenceNumberSection = withStyles(styles, muiOptions)(_ReferenceNumberSection)
export let SiteSection = withStyles(styles, muiOptions)(_SiteSection)
export let VolumeSection = withStyles(styles, muiOptions)(_VolumeSection)
export let TolerancesSection = withStyles(styles, muiOptions)(_TolerancesSection)
export let CommentSection = withStyles(styles, muiOptions)(_CommentSection)