import { useState } from "react"
import { Guid } from '../../infrastructure/guid'
import { Item } from "./components/select/types"

export function useFilter<T = string | Guid>() {
    let [items, setItems] = useState<Item<T>[] | string[]>([])
    let [selectedItems, setSelectedItems] = useState<T[]>([])

    let values = (): T[] => {
        if (selectedItems.length !== 0)
            return selectedItems
        else
            return items.map(x => typeof x === 'string' ? x : x.value)
    }

    let firstItem = (): string | T | null => {
        if (items.length < 1)
            return null
        return typeof items[0] === 'string' ? items[0] : items[0].value
    }

    return { items, setItems, selectedItems, setSelectedItems, values, firstItem }
}

export type Filter = ReturnType<typeof useFilter>

type Values<T> = {
    [P in keyof T]: (string[] | Guid[])
}

export function mapValues<T extends { [key: string]: Filter }>(obj: T): Values<T> {
    let result = {} as Values<T>

    for (let prop in obj)
        result[prop] = obj[prop].values() as any

    return result
}