import { Button } from '@material-ui/core'
import { useActionDebounce } from '../debounce'
import React, { MouseEvent, PropsWithChildren, useState } from 'react';

export type Props = {
    id?: string
    label?: string | JSX.Element
    className?: string
    classes?: any
    disabled?: boolean
    img?: string | JSX.Element
    color?: 'inherit' | 'default' | 'primary' | 'secondary'
    autoFocus?: boolean
    tourKey?: string
    onClick?: ((event: MouseEvent<HTMLButtonElement>) => any | Promise<any>) | (() => any | Promise<any>)
    debouncerDelay?: number
    waitPromise?: boolean
}

export function CustomButton(props: Props) {
    let [waiting, setWaiting] = useState(false)
    let imgType = props.img == null ? null : typeof props.img === 'string' ? 'string' : 'jsx'
    let disabled = !!props.disabled || (!!props.waitPromise && waiting)

    let onClickWaitPromise = async (evt: MouseEvent<HTMLButtonElement>) => {
        if (!props.onClick) return
        try {
            setWaiting(true)
            return await props.onClick(evt)
        } finally {
            setWaiting(false)
        }
    }

    let onClickAction: (((event: MouseEvent<HTMLButtonElement>) => any | Promise<any>) | (() => any | Promise<any>) | undefined) =
        props.waitPromise
            ? onClickWaitPromise
            : props.debouncerDelay && props.onClick
                ? useActionDebounce(props.onClick, props.debouncerDelay!).execute
                : props.onClick

    return (
        <Button
            id={props?.id}
            data-tour={props.tourKey}
            className={props.className || ''}
            classes={props.classes ?? {}}
            disabled={disabled}
            color={props.color ?? 'default'}
            autoFocus={!!props.autoFocus}
            onClick={(event) => { if (onClickAction) { onClickAction(event) } }}
            data-testid={props?.id}>
            {imgType === 'string' ?
                <>&nbsp;&nbsp;<img src={props.img as string} style={{ height: '1.7em', width: '1.7em' }} />&nbsp;&nbsp;</>
                : imgType === 'jsx' ?
                    <>&nbsp;{props.img}&nbsp;&nbsp;</>
                    : null}
            {props.label ?? ''}
            {imgType ? <>&nbsp;&nbsp;</> : null}
        </Button>)
}

export type LabelButtonProps = {
    id?: string
    label?: string | JSX.Element
    className?: string
    classes?: any
    disabled?: boolean
    img?: string | JSX.Element
    color?: 'inherit' | 'default' | 'primary' | 'secondary'
}

export function LabelButton(props: PropsWithChildren<LabelButtonProps>) {
    let imgType = props.img == null ? null : typeof props.img === 'string' ? 'string' : 'jsx'
    return (
        <Button
            id={props?.id}
            className={props.className || ''}
            classes={props.classes ?? {}}
            disabled={!!props.disabled}
            color={props.color ?? 'default'}
            component="label">
            {props.children}
            {imgType === 'string' ?
                <>&nbsp;&nbsp;<img src={props.img as string} style={{ height: '1.7em', width: '1.7em' }} />&nbsp;&nbsp;</>
                : imgType === 'jsx' ?
                    <>&nbsp;{props.img}&nbsp;&nbsp;</>
                    : null}
            {props.label ?? ''}
            {imgType ? <>&nbsp;&nbsp;</> : null}
        </Button>)
}

type Ticks = number