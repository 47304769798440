import React, { useEffect } from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { MultipleSelect, GroupedMultiSelect, DateRangePicker } from '../../common/customComponents'
import { StockBoardContainer } from '../stockBoardStore'
import { Item } from '../../common/components/select/types'

function _StockBoardFilters({ classes }: MuiProps) {
    let store = StockBoardContainer.useContainer()

    useEffect(() => { store.loadFiltersElements() }, [])

    let hasOneCompany = store.companies.map(x => x.code).length === 1

    return (
        <div className={'stockFilters'}>
            <DateRangePicker startDate={store.stockFilters.start || null} startLabel={t('stock.filters.startDate')}
                endDate={store.stockFilters.end || null} endLabel={t('stock.filters.endDate')} disableNewStyle
                onPeriodChange={period => store.changePeriod(period)}
                onError={store.filterDatesOnError} classesOverride={{ datepicker: classes.filterFieldDate }} />
            <GroupedMultiSelect
                label={t('stock.filters.sites')}
                classesOverride={{ form: classes.filterField }}
                values={store.stockFilters.sitesFilter.selectedItems} disableNewStyle
                choices={store.stockFilters.sitesFilter.items as Item<string>[]}
                onChange={x => store.stockFilters.sitesFilter.setSelectedItems(x)} />
            {store.products.every(x => !x.productGroup)
                ? <MultipleSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    filter={store.stockFilters.productIdsFilter} disableNewStyle hideOnSingleChoice />
                : <GroupedMultiSelect label={t('stock.filters.product')} classesOverride={{ form: classes.filterField }}
                    values={store.stockFilters.productIdsFilter.selectedItems} hideOnSingleChoice disableNewStyle
                    choices={store.stockFilters.productIdsFilter.items as Item<string>[]}
                    onChange={x => store.stockFilters.productIdsFilter.setSelectedItems(x)} />}
            <MultipleSelect label={t('stock.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                filter={store.stockFilters.dutyStatusesFilter} disableNewStyle hideOnSingleChoice />
            <MultipleSelect label={t('stock.filters.company')} classesOverride={{ form: classes.filterField }}
                values={hasOneCompany ? [store.companies[0].code] : store.stockFilters.companiesFilter.selectedItems}
                filter={store.stockFilters.companiesFilter} onChange={store.changeCompanies} disableNewStyle />
        </div>
    )
}

let styles = theme =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        },
        filterFieldDate: {
            width: '10.3em',
            margin: '0em 0.5em'
        },
        searchFilter: {
            ...defaultStyles.secondaryButton
        }
    })

export let StockBoardFilters = withStyles(styles, muiOptions)(_StockBoardFilters)