import React from 'react'
import { TextField, Select as CustomSelect, DatePicker, NumberField, Switch as CustomSwitch } from '../../common/customComponents'
import { TruckTransportContainer } from '../truckStore'
import { TruckTransportFormModel } from '../truckModels'
import { getTradCode } from './truckForm'
import { Add, DeleteOutlined } from '@material-ui/icons'
import { defaultColors } from '../../../infrastructure/materialUiThemeProvider'
import { IconButton } from '@material-ui/core'
import guid from '../../../infrastructure/guid'
import { Item } from '../../common/components/select/types'

export function Text({ name, disabled, id, customTraductionKey, fullWidth }: TextFormField) {
    let store = TruckTransportContainer.useContainer()
    return (
        <TextField
            fullWidth={fullWidth}
            label={store.t(customTraductionKey ? getTradCode('field.' + customTraductionKey) : getTradCode('field.' + name))}
            id={id}
            disabled={!!disabled}
            text={store.form.truck[name] as (string | null)}
            status={store.form.fieldStatus.getStatus(name)}
            onChange={e => store.form.setValue(name, e.target.value)} />)
}

export function Number({ name, id, tradParam }: NumberFormField) {
    let store = TruckTransportContainer.useContainer()
    return (
        <NumberField label={store.t(getTradCode('field.' + name), tradParam)}
            id={id}
            text={store.form.truck[name] as (number | null)}
            status={store.form.fieldStatus.getStatus(name)}
            onChange={val => store.form.setValue(name, val)} />)
}

export function Select({ name, choices, id }: SelectFormField) {
    let store = TruckTransportContainer.useContainer()
    return (
        <CustomSelect label={store.t(getTradCode('field.' + name))}
            id={id}
            value={store.form.truck[name]}
            status={store.form.fieldStatus.getStatus(name)}
            choices={choices}
            onChange={val => { if (val) store.form.setValue(name, val) }} />
    )
}

export function Date({ name, customTraductionKey, id }: DateFormField) {
    let store = TruckTransportContainer.useContainer()
    return (
        <DatePicker
            id={id}
            label={store.t(customTraductionKey ? getTradCode('field.' + customTraductionKey) : getTradCode('field.' + name))}
            date={store.form.truck[name] as string | null ?? null}
            status={store.form.fieldStatus.getStatus(name)}
            setDate={val => store.form.setValue(name, val)} />
    )
}

export function Switch({ name, disabled, id }: SwitchFormField) {
    let store = TruckTransportContainer.useContainer()
    return (
        <div style={{ display: 'inline-block', marginTop: '0.6em' }}>
            <CustomSwitch form
                id={id}
                disabled={disabled}
                onText={store.t(getTradCode('field.' + name))}
                offText={''}
                isChecked={!!store.form.truck[name]}
                changeCallback={() => store.form.setValue(name, !store.form.truck[name])} />
        </div>
    )
}

export function EntryLines() {
    let store = TruckTransportContainer.useContainer()
    let truck = store.form.truck

    let entrysQuantities = truck.entrys.map(x => x.quantity).filter(x => x)
    let shouldBeOnError = false

    if (entrysQuantities.length)
        shouldBeOnError = entrysQuantities.reduce((a, b) => a! + b!) !== truck.loadedQuantity

    return (
        <div>
            {
                truck.entrys.length > 1
                    ? truck.entrys.map((x, index) =>
                        <div key={x.id} style={{ marginTop: '0.5em' }}>
                            <TextField label={store.t(getTradCode('field.entryNumber') + (index + 1))}
                                text={x.entryNumber}
                                onChange={e => store.form.changeEntryNumber(index, e.target.value)} />
                            <NumberField label={store.t(getTradCode('field.loadedQuantity'), { unit: truck.quantityUnit })} text={x.quantity}
                                onError={shouldBeOnError} errorMessage={store.t(getTradCode('field.invalidEntrysQuantity'))}
                                onChange={val => store.form.changeEntryQuantity(index, val)} />
                            {index === truck.entrys.length - 1
                                ? <IconButton onClick={() => store.form.addTruckFormEntry({ id: guid.createNew() })}
                                    style={{ color: defaultColors.red.main.color, marginTop: '0.5em' }}>
                                    <Add />
                                </IconButton>
                                : <IconButton onClick={() => store.form.deleteTruckFormEntry(index)}
                                    style={{ color: defaultColors.red.main.color, marginTop: '0.5em' }}>
                                    <DeleteOutlined />
                                </IconButton>}
                        </div>)
                    : null
            }
        </div >
    )
}

export function SingleEntryLine() {
    let store = TruckTransportContainer.useContainer()
    let truck = store.form.truck
    let firstEntry = truck.entrys.first()

    return (
        <div style={{ marginTop: '0.5em' }}>
            <TextField label={store.t(getTradCode('field.entryNumber'))}
                text={firstEntry.entryNumber}
                onChange={e => store.form.changeEntryNumber(0, e.target.value)} />
            <NumberField label={store.t(getTradCode('field.loadedQuantity'), { unit: truck.quantityUnit })}
                text={store.form.truck.loadedQuantity}
                onChange={val => {
                    store.form.setValue('loadedQuantity', val);
                    store.form.changeEntryQuantity(0, val)
                }} />

            <IconButton onClick={() => store.form.addTruckFormEntry({ id: guid.createNew() })}
                style={{ color: defaultColors.red.main.color, marginTop: '0.5em' }}>
                <Add />
            </IconButton>

        </ div >)
}

type BaseFormField = {
    name: keyof TruckTransportFormModel
    customTraductionKey?: string
    disabled?: boolean
    id?: string
    tradParam?: any
    multiline?: boolean
    fullWidth?: boolean
}

type TextFormField = BaseFormField

type NumberFormField = BaseFormField

type SelectFormField = BaseFormField & {
    choices: Item<any>[] | string[]
}

type DateFormField = BaseFormField

type SwitchFormField = BaseFormField