import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Add, DeleteOutlined } from '@material-ui/icons'
import {
    Button,
    Typography
} from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../infrastructure/i18nextHelper'
import { hasFeature } from '../../../../infrastructure/feature'
import { Select } from '../../../common/customComponents'
import { VesselEditContainer } from '../vesselEditStore'
import { PurchaseMovement, MovementType, SitePurchaseMovement, VesselProduct } from '../../vesselModels'
import { commonStyle, TotalDispatchedQuantity, Unbalances } from './_common'
import { SelectAssignableDeals, ConfirmDealAssignation } from '../../../common/assign/assignDealPopup'
import { PriceSeveralMovementTypesTable } from './priceSeveralMovementTypesTable'
import { PricePurchaseMovementTable } from './pricePurchaseMovementTable'
import { Item } from '../../../common/components/select/types'

let tBase = 'vessels.label.purchaseMovement.'

export type PurchaseMovementTableProps = {
    vesselProduct: VesselProduct
    purchaseMovement: PurchaseMovement
    canDeletePurchaseMovement: boolean
    isFixedPrice: boolean
    addSite: (purchaseMvt: PurchaseMovement, defaultSitePurchaseMovement: SitePurchaseMovement) => void
    createSite: (company: string, dutyStatus: string) => SitePurchaseMovement
    duplicateSite: (site: SitePurchaseMovement) => SitePurchaseMovement
    setPurchaseMovementToDelete: () => void
    setSitePurchaseMovementToDelete: (site: SitePurchaseMovement) => void
}

function _PriceMovementContainer(props: PurchaseMovementTableProps & MuiProps) {
    let { classes, vesselProduct, purchaseMovement, canDeletePurchaseMovement, isFixedPrice,
        createSite, duplicateSite, addSite, setPurchaseMovementToDelete, setSitePurchaseMovementToDelete } = props

    let [selectedSite, setSelectedSite] = useState<string>(purchaseMovement.sites[0]?.siteCode ?? '')
    let vessel = VesselEditContainer.useContainer()

    let hasOnlyOneCompany = vessel.movementStock.map(x => x.company).distinct().length == 1
    let hasOnlyOneDutyStatus = vessel.movementStock.map(x => x.dutyStatus).distinct().length == 1

    let canHaveSeveralMovementTypes = !hasFeature('VesselAutoCreateDeal')
        && hasFeature("SalesAndTransfersInVessels")
        && hasOnlyOneCompany
        && hasOnlyOneDutyStatus

    let sites = (purchaseMvt: PurchaseMovement): Item<string>[] => {
        if (!vessel.movementStock || !vessel.sites)
            return []

        let purchaseMovementStockSites = vessel.movementStock
            .filter(x => x.company === purchaseMvt.companyCode
                && x.dutyStatus === purchaseMvt.dutyStatus
                && x.productId === vesselProduct.productId)
            .map(x => x.site)
        return vessel.sites
            .filter(x => purchaseMovementStockSites.includes(x.code))
            .map(x => ({ value: x.code, text: x.name }))
    }

    let addNewMovement = () => {
        let site = createSite(purchaseMovement.companyCode, purchaseMovement.dutyStatus)
        if (purchaseMovement.type === "FixedPrice")
            site = vessel.applyFixedPrice(vesselProduct.id, site)
        if (canHaveSeveralMovementTypes)
            site.siteCode = selectedSite
        addSite(purchaseMovement, site)
    }

    let mainUnbalance = () => {
        return purchaseMovement.sites.map(x => x.movementType == MovementType.PurchaseType
            ? x.quantity ?? 0
            : -(x.quantity ?? 0)
        ).reduce((prev, curr) => curr = curr + prev, 0)
    }

    let productUnitUnbalance = () => {
        return purchaseMovement.sites.map(x => x.movementType == MovementType.PurchaseType
            ? x.volume ?? 0
            : -(x.volume ?? 0)
        ).reduce((prev, curr) => curr = curr + prev, 0)
    }

    let company = vessel.companys[purchaseMovement.companyCode]
    let blockHeaderClass = hasOnlyOneCompany && hasOnlyOneDutyStatus
        ? classes.headerActionAndQuantityFullWidth
        : classes.headerActionAndQuantity

    let products = Object.keys(vessel.products)
        .map((x) => { return { code: vessel.products[x], id: x } })
    let disableSitesSelection = purchaseMovement.sites.some(x => x.movementId != null)

    return <div>
        <div className={classes.purchaseMovementHeader}>
            {hasOnlyOneCompany && hasOnlyOneDutyStatus
                ? undefined
                : <Typography className={classes.purchaseMovementHeaderCombination}
                    variant='h6' display='block'>
                    {hasOnlyOneCompany
                        ? purchaseMovement.dutyStatus
                        : hasOnlyOneDutyStatus
                            ? company
                            : company + " / " + purchaseMovement.dutyStatus}
                </Typography>}
            <div className={blockHeaderClass}>
                {canHaveSeveralMovementTypes
                    ? <div className={classes.quantitiesBottom}>
                        <Typography variant='subtitle2'>{t(tBase + 'site')}</Typography>
                        <Select disableNewStyle
                            disabled={disableSitesSelection}
                            classesOverride={{ form: classes.purchaseMovementHeaderCombination, select: 'site-select' }}
                            value={selectedSite ?? sites(purchaseMovement)[0]?.value}
                            choices={sites(purchaseMovement)}
                            onChange={val => setSelectedSite(val)} />
                    </div>
                    : undefined}
                <TotalDispatchedQuantity
                    nominatedQuantity={purchaseMovement.nominatedQuantity}
                    updateNominatedQuantity={v =>
                        vessel.setPurchaseMovement(vesselProduct.id, { ...purchaseMovement, nominatedQuantity: v })}
                    quantity={purchaseMovement.totalDispatchedQuantity}
                    percentage={purchaseMovement.percentageOfNominated}
                    inAlert={purchaseMovement.inAlert}
                    dispatchedLabel={t(tBase + 'dispatchedQuantity')}
                    withoutDispatchedQuantity={canHaveSeveralMovementTypes}
                    classes={classes} />
                {canHaveSeveralMovementTypes
                    ? <Unbalances
                        mainUnbalance={mainUnbalance()}
                        mainUnit={'TO'}
                        productUnitUnbalance={productUnitUnbalance()}
                        productUnit={vesselProduct.productUnit}
                        classes={classes} />
                    : undefined}
                <div className={classes.purchaseMovementActions}>
                    <Button onClick={() => addNewMovement()}
                        className={`${classes.headerButton} add-site-btn`}>
                        <Add /> {t(tBase + 'addMovement')}
                    </Button>
                    {canDeletePurchaseMovement
                        ? <Button
                            className={`${classes.headerButton} add-site-btn`}
                            onClick={() => setPurchaseMovementToDelete()} >
                            <DeleteOutlined /> {t(tBase + 'deletePurchaseMovement')}
                        </Button>
                        : undefined}
                </div>
            </div>
        </div>
        {canHaveSeveralMovementTypes
            ? <PriceSeveralMovementTypesTable vesselProduct={vesselProduct}
                isFixedPrice={isFixedPrice}
                purchaseMovement={purchaseMovement}
                setPurchaseMovementToDelete={setPurchaseMovementToDelete}
                setSitePurchaseMovementToDelete={setSitePurchaseMovementToDelete}
                canDeletePurchaseMovement={canDeletePurchaseMovement}
                addSite={addSite}
                createSite={createSite}
                duplicateSite={duplicateSite}
                purchaseMovementSite={selectedSite} />
            : <PricePurchaseMovementTable vesselProduct={vesselProduct}
                isFixedPrice={isFixedPrice}
                purchaseMovement={purchaseMovement}
                setPurchaseMovementToDelete={setPurchaseMovementToDelete}
                setSitePurchaseMovementToDelete={setSitePurchaseMovementToDelete}
                canDeletePurchaseMovement={canDeletePurchaseMovement}
                addSite={addSite}
                createSite={createSite}
                duplicateSite={duplicateSite} />}
        <SelectAssignableDeals
            classes={classes}
            setOpenAssignDealPopup={vessel.setOpenAssignDealPopup}
            setDealId={vessel.setDealIdToAssign}
            setIsConfirmDealOpen={vessel.setIsConfirmDealOpen}
            products={products}
            counterpartys={vessel.counterpartys.map(({ name, id }) => ({ name, id }))}
            openAssignDealPopup={vessel.openAssignDealPopup}
            assignableDealsFromMovement={vessel.assignableDealsFromMovement} />
        <ConfirmDealAssignation
            setIsConfirmDealOpen={vessel.setIsConfirmDealOpen}
            isConfirmDealOpen={vessel.isConfirmDealOpen}
            setOpenAssignDealPopup={vessel.setOpenAssignDealPopup}
            setDealIdToAssign={vessel.setDealIdToAssign}
            trySave={vessel.trySave}
            openAssignDealPopup={vessel.openAssignDealPopup}
            assignableDealsFromMovement={vessel.assignableDealsFromMovement}
            assignDealFromMovement={vessel.assignDealFromMovement}
            dealIdToAssign={vessel.dealIdToAssign}
        />
    </div>
}

export let PriceMovementContainer = withStyles(commonStyle, muiOptions)(_PriceMovementContainer)