import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { SupplyBalanceStore } from '../supplyBalanceStore'
import { MultipleSelect, GroupedMultiSelect } from '../../common/customComponents'
import { Item } from 'src/app/common/components/select/types'

function _SupplyBalanceFilters({ classes }) {
    let store = SupplyBalanceStore.useContainer()

    let hasOneCompany = store.companies.map(x => x.code).length === 1

    return (
        <div className="supplyBalanceFilters">
            <GroupedMultiSelect
                label={t('supplyBalance.filters.sites')}
                classesOverride={{ form: classes.filterField }}
                values={store.filters.sitesFilter.selectedItems}
                choices={store.filters.sitesFilter.items as Item<string>[]}
                disableNewStyle
                onChange={x => store.filters.sitesFilter.setSelectedItems(x)} />
            {store.products.every(x => !x.productGroup)
                ? <MultipleSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    filter={store.filters.productIdsFilter} disableNewStyle hideOnSingleChoice />
                : <GroupedMultiSelect label={t('supplyBalance.filters.products')} classesOverride={{ form: classes.filterField }}
                    values={store.filters.productIdsFilter.selectedItems} hideOnSingleChoice
                    choices={store.filters.productIdsFilter.items as Item<string>[]} disableNewStyle
                    onChange={x => store.filters.productIdsFilter.setSelectedItems(x)} />}
            <MultipleSelect label={t('supplyBalance.filters.dutyStatus')} classesOverride={{ form: classes.filterField }}
                filter={store.filters.dutyStatusesFilter} disableNewStyle />
            <MultipleSelect label={t('supplyBalance.filters.companies')} classesOverride={{ form: classes.filterField }}
                values={hasOneCompany ? store.companies.map(x => x.code) : store.filters.companiesFilter.selectedItems}
                filter={store.filters.companiesFilter} disableNewStyle />
        </div>
    )
}

let styles = _ =>
    createStyles({
        filterField: {
            width: '8.3em',
            margin: '0em 0.5em'
        }
    })

export let SupplyBalanceFilters = withStyles(styles, muiOptions)(_SupplyBalanceFilters)