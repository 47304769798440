import React from 'react'
import { createStyles, withStyles, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { DatePicker, Select, NumberField } from '../../../common/customComponents'
import { t } from '../../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, defaultColors } from '../../../../infrastructure/materialUiThemeProvider'
import { StockMovementContainer } from '../stockMovementStore'
import { BatchMode, Day } from '../../stockModels'
import { Item } from '../../../common/components/select/types'

function _batchForm({ classes }) {
    let store = StockMovementContainer.useContainer()

    let handleChangeDay = (day: string) => {
        if (!store.batchMovement) return

        let chosenDay: Day = Day[day]
        let dayIndex = store.batchMovement?.days?.findIndex(x => x == chosenDay) ?? -1;
        let days = store.batchMovement?.days ?? []
        if (dayIndex == -1)
            days?.push(chosenDay)
        else
            days?.splice(dayIndex, 1)

        store.setBatchMovement({ ...store.batchMovement, days: days ?? [] })
    }

    let batchModeValues: Item<number>[] =
        Object.keys(BatchMode).filter(x => isNaN(+x)).map((x, i) => { return { value: i, text: x.valueOf() } })
    let days = Object.keys(Day).filter(x => isNaN(+x)).map((x, i) =>
        <FormControlLabel key={i} control={
            <Checkbox checked={store.batchMovement?.days?.findIndex(x => x == i) != -1}
                onChange={() => handleChangeDay(x)}
                classes={{ checked: classes.checkboxChecked, root: classes.noPadding }}
                color='default' />}
            label={x}
        />)

    let hasDateError = store.formErrors.hasMovementError('stockInputDate') || store.formErrors.hasMovementError('stockOutputDate')

    return (
        <div>
            {hasDateError
                ? <Typography className={classes.errorMessage} variant='body2' display='block' gutterBottom>
                    {!store.formErrors.specialRules.isBatchMovementSameDateValid ? t('stock.label.errors.diffDates')
                        : !store.formErrors.specialRules.isBatchMovementDateStartsAfterEndValid ? t('stock.label.errors.startAfterEnd')
                            : t('stock.label.errors.62DaysDiff')}
                </Typography>
                : null}
            <div className={classes.row}>
                <DatePicker label={t('stock.batch.fromDate')} date={store.batchMovement?.fromDate ?? null}
                    setDate={newDate => { if (store.batchMovement) store.setBatchMovement({ ...store.batchMovement, fromDate: newDate }) }}
                    onError={hasDateError} />
                <DatePicker label={t('stock.batch.toDate')} date={store.batchMovement?.toDate ?? null}
                    setDate={newDate => { if (store.batchMovement) store.setBatchMovement({ ...store.batchMovement, toDate: newDate }) }}
                    onError={hasDateError} />
                <NumberField label={t('stock.batch.every')} text={store.batchMovement?.every ?? null}
                    onChange={x => { if (store.batchMovement) store.setBatchMovement({ ...store.batchMovement, every: x ? parseInt(x.toString()) : null }) }}
                    onError={!store.batchMovement?.every} />
                <Select label={t('stock.batch.period')} value={store.batchMovement?.mode ?? ''} choices={batchModeValues}
                    onChange={val => {
                        if (val && store.batchMovement != null)
                            store.setBatchMovement({ ...store.batchMovement, mode: parseFloat(val) })
                    }} />
                {store.batchMovement?.mode == BatchMode.Week
                    ? <div className={classes.days}>{days}</div>
                    : null}
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        row: {
            ...defaultStyles.flexRow,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: '1em',
            marginBottom: '1em'
        },
        days: {
            display: 'flex',
            flexWrap: 'wrap',
            marginLeft: '2%',
            width: '40%'
        },
        noPadding: {
            padding: '0px',
        },
        checkboxChecked: {
            color: defaultColors.red.main.color,
        },
        errorMessage: {
            color: defaultColors.red.light.color
        },
    })

export default withStyles(styles, muiOptions)(_batchForm)
