import { DealMovementItem } from "../deals/dealModels";
import { MovementListItem, SapFlowStepStatus } from "../stock/stockModels";

export let isMultiSapFlow = (x: DealMovementItem | MovementListItem) => x.secondSapFlowListItem != null && x.secondSapFlowListItem.steps.length > 0

export let steps = (x: MovementListItem | DealMovementItem, showAll: boolean): { mainSapFlow: SapFlowStepStatus[], secondSapFlow?: SapFlowStepStatus[] } => {
    if (showAll)
        return {
            mainSapFlow: x.mainSapFlowListItem.steps,
            secondSapFlow: x.secondSapFlowListItem?.steps
        }
    else if (isMultiSapFlow(x))
        return {
            mainSapFlow: [x.mainSapFlowListItem.steps[0], x.secondSapFlowListItem!.steps[0]]
        }
    else
        return {
            mainSapFlow: x.mainSapFlowListItem.steps
        }
}