import { Guid } from './../../infrastructure/guid'
import moment from 'moment'

export type Site = {
    code: string
    name: string
    siteGroup: string
}

export type Product = {
    id: Guid
    code: string
    productGroup: string | null
}

export type DutyStatus = string

export type Company = {
    code: string
    name: string
    dutyStatuses: DutyStatus[]
}

export type SupplyBalanceFilter = {
    sites: string[]
    productIds: Guid[]
    dutyStatuses: DutyStatus[]
    companies: string[]
    usefulStock: boolean
}

export type SearchSupplyBalances = {
    startDate: string
    endDate: string
    sites: string[]
    products: Guid[]
    dutyStatuses: string[]
    companys: string[]
    usefulStock: boolean
}

export type SearchSupplyBalancesResult = {
    balances: SupplyBalance[]
    total: SupplyBalanceTotal
}

export type SupplyBalance = {
    siteGroup: string
    site: string
    siteName: string
    productId: Guid
    productCode: string
    productOrder: number
    demand: SupplyDemand
    resource: SupplyResource
    need: SupplyNeed
    isSubTotal: boolean
}

export type SupplyBalanceTotal = {
    demand: SupplyDemand
    resource: SupplyResource
}

export type SupplyDemand = {
    sales: number | null
    transit: number | null
    rebranding: number | null
    statusChange: number | null
    loan: number | null
    losses: number | null
}

export type SupplyResource = {
    stockProjOpening: number | null
    purchaseVessel: number | null
    purchaseSpot: number | null
    transit: number | null
    rebranding: number | null
    statusChange: number | null
    borrow: number | null
    gains: number | null
}

export type SupplyNeed = {
    stockTarget: number | null
    stockTargetDays: number | null
    need: number | null
    sellingDays: number
    averageDemandPerDay: number | null
    deadStock: number | null
    lowVolume: number | null
    highVolume: number | null
    maxCapacity: number | null
    ullageStart: number | null
    ullageEnd: number | null
}

export type SupplyBalancePeriod = {
    start: string | null
    end: string | null
}

export type SupplyBalanceTableData = {
    supplyBalanceResult: SearchSupplyBalancesResult
    period: SupplyBalancePeriod
}

export type SupplyBalanceRxFilters = {
    latestFilters: SupplyBalanceFilter
    lastestSupplyBalanceTables: SupplyBalanceTableData[]
}

export const defaultStartDate = () => moment.utc().startOf('month').format('MM/DD/YYYY')

export const defaultEndDate = () => moment.utc().startOf('month').add(2, 'M').add(-1, 'day').format('MM/DD/YYYY')

const defaultSupplyBalanceTotal = (): SupplyBalanceTotal => {
    return {
        demand: defaultSupplyDemand(),
        resource: defaultSupplyResource()
    }
}

const defaultSupplyDemand = (): SupplyDemand => {
    return {
        sales: null,
        transit: null,
        rebranding: null,
        statusChange: null,
        loan: null,
        losses: null,
    }
}

const defaultSupplyResource = (): SupplyResource => {
    return {
        stockProjOpening: null,
        purchaseVessel: null,
        purchaseSpot: null,
        transit: null,
        rebranding: null,
        statusChange: null,
        borrow: null,
        gains: null,
    }
}

export const defaultSupplyBalanceResult = (): SearchSupplyBalancesResult => {
    return {
        balances: [],
        total: defaultSupplyBalanceTotal()
    }
}

export const defaultSupplyBalanceTable = (startDate: string | null): SupplyBalanceTableData => {
    return {
        supplyBalanceResult: defaultSupplyBalanceResult(),
        period: { start: startDate, end: null }
    }
}

export const defaultSupplyBalancePeriod = (): SupplyBalancePeriod => {
    return {
        start: defaultStartDate(),
        end: defaultEndDate()
    }
}