import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Add, DeleteOutlined, GavelOutlined, TrendingFlat } from '@material-ui/icons'
import {
    Button, Table, TableBody, TableHead,
    TableRow, IconButton, Tooltip
} from '@material-ui/core'
import { muiOptions, MuiProps } from '../../../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../../../infrastructure/i18nextHelper'
import { Guid } from '../../../../../infrastructure/guid'
import { popupNavigator } from '../../../../../infrastructure/popupNavigator'
import { TextField, NumberField, Select, DatePicker } from '../../../../common/customComponents'
import { VesselEditContainer } from '../../vesselEditStore'
import { VesselProduct, PurchaseMovement, SitePurchaseMovement } from '../../../vesselModels'
import { commonStyle, Cell, RightAlignedCell, TotalDispatchedQuantity } from '../_common'
import { hasFeature } from '../../../../../infrastructure/feature'
import { Item } from '../../../../common/components/select/types'

let tBase = 'vessels.label.purchaseMovement.'

type PurchaseMovementTableProps = {
    vesselProduct: VesselProduct
    purchaseMovement: PurchaseMovement
    canDeletePurchaseMovement: boolean
    addSite: (purchaseMvt: PurchaseMovement, defaultSitePurchaseMovement: SitePurchaseMovement) => void
    createSite: (company: string, dutyStatus: string) => SitePurchaseMovement
    setPurchaseMovementToDelete: () => void
    setSitePurchaseMovementToDelete: (site: SitePurchaseMovement) => void
}

function _TriggerPurchaseMovementTable({ classes, vesselProduct, purchaseMovement, canDeletePurchaseMovement, addSite, createSite, setPurchaseMovementToDelete, setSitePurchaseMovementToDelete }: PurchaseMovementTableProps & MuiProps) {
    let vessel = VesselEditContainer.useContainer()
    let [allowsToAddSite, setAllowsToAddSite] = useState<boolean>(!!purchaseMovement.remainingQuantity && purchaseMovement.remainingQuantity > 0)

    let updateNominatedQuantity = (newValue: number | null) => {
        let siteVolumeTotal = purchaseMovement.sites.reduce((acc, curr) => acc + (curr.quantity ?? 0), 0)
        let newRemaining
        let newPercentage
        if (newValue && newValue >= siteVolumeTotal) {
            newRemaining = parseFloat((newValue - siteVolumeTotal).toFixed(3))
            newPercentage = parseFloat((newRemaining / newValue).toPrecision(3))
        }
        else if (newValue) {
            newRemaining = 0
            newPercentage = 1
        }
        vessel.setPurchaseMovement(vesselProduct.id, { ...purchaseMovement, nominatedQuantity: newValue, percentageOfNominated: newPercentage, remainingQuantity: newRemaining, inAlert: false })
        setAllowsToAddSite(!!newRemaining && newRemaining > 0)
    }

    let sites = (purchaseMvt: PurchaseMovement): Item<string>[] => {
        if (!vessel.movementStock || !vessel.sites)
            return []

        let purchaseMovementStockSites = vessel.movementStock
            .filter(x => x.company === purchaseMvt.companyCode && x.dutyStatus === purchaseMvt.dutyStatus && x.productId === vesselProduct.productId)
            .map(x => x.site)
        return vessel.sites
            .filter(x => purchaseMovementStockSites.includes(x.code))
            .map(x => ({ value: x.code, text: x.name }))
    }

    let updateContractualDensity = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, newValue: number | null) => {
        updateSite(purchaseMvt, site, site => {
            return {
                ...site, contractualDensity: newValue,
                contractualDensityOverwritten: newValue != null,
            }
        })
    }

    let updateSite = (purchaseMvt: PurchaseMovement, site: SitePurchaseMovement, update: (site: SitePurchaseMovement) => SitePurchaseMovement) => {
        let siteIndex = purchaseMvt.sites.findIndex(x => x.id === site.id)
        let updatedSite = update(site)
        let diffQuantity = (site.quantity ?? 0) - (updatedSite.quantity ?? 0)

        if (purchaseMvt.remainingQuantity != null && updatedSite.quantity != null && purchaseMvt.remainingQuantity + diffQuantity < 0)
            updatedSite.quantity = (site.quantity ?? 0) + purchaseMvt.remainingQuantity

        purchaseMvt.sites[siteIndex] = { ...updatedSite }
        if (purchaseMvt.nominatedQuantity) {
            let siteVolumeTotal = purchaseMvt.sites.reduce((acc, cur) => acc + (cur.quantity ?? 0), 0)
            purchaseMvt.remainingQuantity = parseFloat((purchaseMvt.nominatedQuantity - siteVolumeTotal).toFixed(3))
            purchaseMvt.percentageOfNominated = parseFloat((purchaseMvt.remainingQuantity / purchaseMvt.nominatedQuantity).toPrecision(3))
            purchaseMvt.inAlert = purchaseMvt.percentageOfNominated < 0
        }

        setAllowsToAddSite(!!purchaseMvt.remainingQuantity && purchaseMvt.remainingQuantity > 0)
        vessel.setPurchaseMovement(vesselProduct.id, { ...purchaseMovement })
    }

    let getMaxVolume = (siteId: Guid): number => {
        let otherSitesVolume = purchaseMovement.sites.reduce((acc, curr) => acc + (curr.id != siteId ? curr.quantity ?? 0 : 0), 0)
        return purchaseMovement.nominatedQuantity ? purchaseMovement.nominatedQuantity - otherSitesVolume : 0
    }

    let handleDeleteSite = (site: SitePurchaseMovement) => {
        let isLastSite = purchaseMovement.sites.length == 1
        if (isLastSite)
            setPurchaseMovementToDelete()
        else {
            setSitePurchaseMovementToDelete(site)
        }
    }

    let isVolume = vesselProduct.productUnit !== 'TO'

    return (<div className={classes.purchaseMovementTriggerTable}>
        <div className={classes.purchaseMovementTriggerActionsHeader}>
            <div className={classes.quantitiesBottomTrigger}>
                <Typography variant='subtitle2'>{t('vessels.label.purchaseMovement.purchaseContract')}</Typography>
                <TextField disableNewStyle
                    label={''}
                    text={purchaseMovement.purchaseContract}
                    overrideStyle={{ root: classes.siteInput }}
                    onChange={e =>
                        vessel.setPurchaseMovement(vesselProduct.id, { ...purchaseMovement, purchaseContract: e.target.value })}
                />
            </div>
            <div className={classes.quantitiesBottomTrigger}>
                <Typography variant='subtitle2'>{t('vessels.label.purchaseMovement.deadlineDate')}</Typography>
                <DatePicker classesOverride={{ datepicker: classes.siteInput }}
                    smallIcon
                    disableNewStyle
                    date={purchaseMovement.deadlineDate}
                    label={''}
                    setDate={(newDate: string | null) =>
                        vessel.setPurchaseMovement(vesselProduct.id, { ...purchaseMovement, deadlineDate: newDate })} />
            </div>
            <TotalDispatchedQuantity
                nominatedQuantity={purchaseMovement.nominatedQuantity}
                updateNominatedQuantity={v => updateNominatedQuantity(v)}
                quantity={purchaseMovement.remainingQuantity}
                percentage={purchaseMovement.percentageOfNominated}
                inAlert={purchaseMovement.inAlert}
                dispatchedLabel={t(tBase + 'remainingVolume')}
                withoutDispatchedQuantity={false}
                classes={classes} />
            <div className={classes.purchaseMovementActions}>
                <Button
                    onClick={() => addSite(purchaseMovement, createSite(purchaseMovement.companyCode, purchaseMovement.dutyStatus))}
                    className={`${classes.headerButton} add-site-btn`}
                    disabled={!allowsToAddSite}>
                    <Add /> {t(tBase + 'addSite')}
                </Button>
                {canDeletePurchaseMovement
                    ? <Button onClick={() => setPurchaseMovementToDelete()} className={`${classes.headerButton} add-site-btn`}>
                        <DeleteOutlined /> {t(tBase + 'deletePurchaseMovement')}
                    </Button>
                    : undefined}
            </div>
        </div>
        <Table>
            <TableHead>
                <TableRow>
                    <Cell>{t(tBase + 'site')}</Cell>
                    <Cell>{t(tBase + 'availabilityDate')}</Cell>
                    <Cell>{t(tBase + 'quantityUnit')}</Cell>
                    {isVolume && <Cell>{t(tBase + 'volumeUnit')}</Cell>}
                    <Cell>{t(tBase + 'triggerDate')}</Cell>
                    <Cell>{t(tBase + 'triggerPrice')}</Cell>
                    <Cell>{t(tBase + 'premium')}</Cell>
                    <Cell>{t(tBase + 'aseReference')}</Cell>
                    {hasFeature('PricingEscalation') && <Cell>{t(tBase + 'contractualDensity', { unit: vesselProduct.productUnit })}</Cell>}
                    <Cell></Cell>
                </TableRow>
            </TableHead>
            <TableBody>
                {purchaseMovement.sites.map((site, i) =>
                    (site.siteCode ?? sites(purchaseMovement)[0]?.value) &&
                    <TableRow key={i} className={classes.sitePurchaseMvtRow}>
                        <Cell key={'siteCode'}>
                            <Select
                                disableNewStyle
                                classesOverride={{ form: classes.select, select: classes.select }}
                                value={site.siteCode ?? sites(purchaseMovement)[0]?.value}
                                choices={sites(purchaseMovement)}
                                onChange={val => updateSite(purchaseMovement, site, s => { return { ...s, siteCode: val } })} />
                        </Cell>
                        <Cell key={'availabilityDate'}>
                            <DatePicker classesOverride={{ datepicker: classes.siteInput }}
                                smallIcon
                                disableNewStyle
                                auto={!site.availabilityDateOverwritten}
                                placeholder={vessel.state.availabilityDate ?? undefined}
                                date={site.availabilityDate}
                                label={''}
                                setDate={(newDate: string | null) => updateSite(purchaseMovement, site, s => { return { ...s, availabilityDate: newDate, availabilityDateOverwritten: newDate != null } })} />
                        </Cell>
                        <Cell key={'quantity'}>
                            <NumberField disableNewStyle
                                max={getMaxVolume(site.id)}
                                key={site.id} label={''}
                                disabled={!purchaseMovement.nominatedQuantity}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateSite(purchaseMovement, site, s => { return { ...s, quantity: vol, quantityOverride: vol != null } })}
                                text={site.quantity}
                                precision={3} />
                        </Cell>
                        {isVolume && <Cell key={'volume'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                disabled={!purchaseMovement.nominatedQuantity}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateSite(purchaseMovement, site, s => { return { ...s, volume: vol, volumeOverride: vol != null } })}
                                text={site.volume}
                                precision={3}
                                auto={!site.volumeOverride} />
                        </Cell>}
                        <Cell key={'triggerDate'}>
                            <DatePicker classesOverride={{ datepicker: classes.siteInput }}
                                smallIcon
                                disableNewStyle
                                date={site.fixedDate}
                                label={''}
                                setDate={(newDate: string | null) => updateSite(purchaseMovement, site, s => { return { ...s, fixedDate: newDate } })} />
                        </Cell>
                        <Cell key={'triggerPrice'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateSite(purchaseMovement, site, s => { return { ...s, fixedPrice: vol } })}
                                text={site.fixedPrice}
                                precision={3}
                                unit={vesselProduct.triggerUnit}
                                align={'right'} />
                        </Cell>
                        <Cell key={'premium'}>
                            <NumberField disableNewStyle
                                key={site.id} label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={vol => updateSite(purchaseMovement, site, s => { return { ...s, premium: vol } })}
                                text={site.premium}
                                precision={3}
                                unit={vesselProduct.triggerUnit}
                                align={'right'} />
                        </Cell>
                        <Cell key={'aseReference'}>
                            <TextField disableNewStyle
                                label={''}
                                overrideStyle={{ root: classes.siteInput }}
                                onChange={e => updateSite(purchaseMovement, site, s => { return { ...s, aseReference: e.target.value } })}
                                text={site.aseReference} />
                        </Cell>
                        {hasFeature('PricingEscalation') &&
                            <Cell key={'contractualDensity'}>
                                <NumberField disableNewStyle
                                    key={site.id} label={''}
                                    overrideStyle={{ root: classes.siteInput }}
                                    onChange={value => updateContractualDensity(purchaseMovement, site, value)}
                                    text={site.contractualDensity}
                                    precision={3} auto={!site.contractualDensityOverwritten} />
                            </Cell>
                        }
                        <RightAlignedCell key={'buttons'}>
                            {!!site.dealId
                                ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToDeal')}</Typography>} placement='top'>
                                    <IconButton className={classes.iconButton} aria-label='go to deal' size={'small'}
                                        onClick={() => popupNavigator.open('deal', site.dealId)}>
                                        <GavelOutlined className='site-open-deal-icon' />
                                    </IconButton>
                                </Tooltip>
                                : undefined}
                            {!!site.movementId
                                ? <Tooltip title={<Typography variant='body1'>{t(tBase + 'goToMovement')}</Typography>} placement='top'>
                                    <IconButton className={classes.iconButton} aria-label='go to movement' size={'small'}
                                        onClick={() => popupNavigator.open('movement', site.movementId)}>
                                        <TrendingFlat className='site-open-movement-icon' />
                                    </IconButton>
                                </Tooltip>
                                : undefined}
                            <IconButton onClick={() => handleDeleteSite(site)} size={'small'}><DeleteOutlined /></IconButton>
                        </RightAlignedCell>
                    </TableRow>)}
            </TableBody>
        </Table>
    </div>)
}

export let TriggerPurchaseMovementTable = withStyles(commonStyle, muiOptions)(_TriggerPurchaseMovementTable)