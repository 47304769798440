import React, { RefObject, useEffect } from 'react'
import { withStyles, createStyles, Grid } from '@material-ui/core'
import { hasFeature } from '../../../infrastructure/feature'
import { t } from '../../../infrastructure/i18nextHelper'
import { Switch } from '../../common/customComponents'
import { muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { StockBoardContainer } from '../stockBoardStore'

function _StockPercentUsefulStockFilters({ classes }: { classes?: any }) {
    let store = StockBoardContainer.useContainer()

    let toggleStockPercentHigh = () => store.stockFilters.setStockPercentHigh(!store.stockFilters.stockPercentHigh)

    let toggleUsefulStock = () => store.stockFilters.setUsefulStock(!store.stockFilters.usefulStock)

    return (
        <div className={'stockFilters'}>
            <Grid container spacing={0} justify='space-between' alignItems='flex-end' direction='row'>
                <UsefulStock isChecked={store.stockFilters.usefulStock} toggleHandler={toggleUsefulStock} />
                {hasFeature('StockPercentUsage') &&
                    <Grid item spacing={0}>
                        <Switch changeCallback={toggleStockPercentHigh} isChecked={store.stockFilters.stockPercentHigh}
                            title={t('stock.filters.stockPercentHighTooltip')}
                            onText={t('stock.filters.stockPercentHighOn')} offText={t('stock.filters.stockPercentHighOff')} />
                    </Grid>}
            </Grid>
        </div>
    )
}

export let UsefulStock = ({ isChecked, toggleHandler }: { isChecked: boolean, toggleHandler: () => void }):
    JSX.Element | null => {
    return (
        <Grid item spacing={0} >
            <Switch changeCallback={toggleHandler} isChecked={isChecked}
                title={t('stock.filters.usefulStockTooltip')}
                onText={t('stock.filters.usefulStockOn')} offText={t('stock.filters.usefulStockOff')} />
        </Grid>
    )
}

type SelectRestrictionDialogProps = {
    restriction: number | null
    startDate: string | null
    endDate: string | null
    onConfirm: (restriction: number, startDate: string, endDate: string) => void
} & MuiProps

let dialogRef: RefObject<{ open: () => void }> | null = null

let styles = _ => createStyles({})

export let StockPercentUsefulStockFilters = withStyles(styles, muiOptions)(_StockPercentUsefulStockFilters)