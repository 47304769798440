import React, { useEffect, useMemo } from 'react'
import { createStyles, withStyles, IconButton } from '@material-ui/core'
import { muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { useContainer } from 'unstated-next'
import { CommentContainer } from './commentStore'
import { commentButtonDialog } from './commentButtonDialog'

function _CommentButton({ classes, ...props }: CommentButtonProps) {
    let store = useContainer(CommentContainer)
    let { associationKey, withPadding, hideIfNoContent, className, readOnly } = props;
    let hasComment = useMemo(() => store.existingKeys.has(associationKey), [store.existingKeys, associationKey])

    useEffect(() => { store.exists(associationKey) }, [associationKey])

    let openDialog = () => commentButtonDialog.open(associationKey, readOnly)

    let getClassName = () => {
        if (hideIfNoContent && !hasComment)
            return classes.hiddenIconButton

        return className ?? ''
    }

    return (
        <div className={getClassName()}>
            <div className={withPadding ? classes.commentButtonPadding : ''}>
                <IconButton size={'small'} onClick={openDialog}>
                    {<img
                        src={hasComment
                            ? '/static/images/chat_icon.svg'
                            : '/static/images/empty_chat_icon.svg '}
                        className={classes.commentIcon}
                        width="10px"
                        height="10px" />}
                </IconButton>
            </div >
        </div>)
}

let styles = _ =>
    createStyles({
        commentIcon: {
            height: '1em',
            width: '1em',
        },
        commentButtonPadding: { padding: '1em' },
        hiddenIconButton: {
            alignItems: 'center',
            background: 'transparent',
            opacity: 0,
            '&:hover': { opacity: '1 !important' }
        }
    })

export let CommentButton = withStyles(styles, muiOptions)(React.memo(_CommentButton))

type CommentButtonProps = {
    associationKey: string
    withPadding?: boolean | null
    hideIfNoContent?: boolean | null
    className?: string
    classes?: any
    readOnly?: boolean
}