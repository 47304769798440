import React, { RefObject, useImperativeHandle, useRef } from 'react'
import { MuiProps } from "../../../infrastructure/materialUiThemeProvider"
import { t } from '../../../infrastructure/i18nextHelper'
import {
    createStyles, withStyles, Dialog,
    DialogContent, DialogActions, DialogTitle,
    DialogContentText
} from '@material-ui/core'
import { defaultStyles, muiOptions } from '../../../infrastructure/materialUiThemeProvider'
import { Button } from '../customComponents'
import { Switch, DateRangePicker } from '../customComponents'

let duplicateDealDialogRef: RefObject<{ open: () => void }> | null = null

export type DuplicateDealDialogProps = {
    isOpen: boolean
    isPeriodMode: boolean
    setIsOpen: (x: boolean) => void
    onDealPeriodChange: (x: DealDuplicatePeriod) => void
    onPeriodModeChange: (x: boolean) => void
    isDuplicateByPeriodNotRestricted: boolean
    onConfirm?: () => void
    startDate: StringDate
    endDate: StringDate
}

type StringDate = string

export type DealDuplicatePeriod = {
    start: StringDate
    end: StringDate
}

function _DuplicateDealDialog(props: MuiProps & DuplicateDealDialogProps) {

    let { classes, isOpen, setIsOpen, onDealPeriodChange,
        startDate, endDate, onConfirm, isDuplicateByPeriodNotRestricted,
        isPeriodMode, onPeriodModeChange } = props

    duplicateDealDialogRef = useRef<{ open: () => void }>(null)

    useImperativeHandle(duplicateDealDialogRef, () => ({
        open: () => {
            setIsOpen(true)
            onPeriodModeChange(true)
        }
    }))

    let close = () => {
        setIsOpen(false)
    }

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle>{t('deals.label.confirmTitle')}</DialogTitle>
            <DialogContent>
                {isDuplicateByPeriodNotRestricted ? (
                    <>
                        <Switch
                            form
                            isChecked={isPeriodMode}
                            changeCallback={() => onPeriodModeChange(!isPeriodMode)}
                            offText={t('deals.label.samePeriod')}
                            onText={t('deals.label.nextPeriod')}
                        />
                        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', margin: '16px' }}>
                            {t('deals.label.confirmDuplicateByPeriod')}
                        </DialogContentText>
                        {isPeriodMode && (
                            <DateRangePicker
                                startDate={startDate}
                                startLabel={t('deals.label.startDate')}
                                endDate={endDate}
                                endLabel={t('deals.label.endDate')}
                                onPeriodChange={({ startDate, endDate }) =>
                                    onDealPeriodChange({ start: startDate!, end: endDate! })
                                }
                                autoReplace={true}
                            />
                        )}
                    </>
                ) : (
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', margin: '16px' }}>
                        {t('deals.label.confirmDuplicateByPeriod')}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions className={classes.marginTop1em}>
                <Button onClick={close}
                    label={t('components.dialogClose')}
                    color='primary'
                    className={classes.cancelButton} />
                <Button onClick={onConfirm}
                    label={t('components.dialogConfirm')}
                    color='primary'
                    className={classes.confirmButton}
                    disabled={false} />
            </DialogActions>
        </Dialog >
    )
}

let styles = () =>
    createStyles({
        confirmButton: { ...defaultStyles.dialogPrimaryButton },
        cancelButton: { ...defaultStyles.dialogCloseButton },
        dialogContent: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        }
    })

export let DuplicateDealDialog = withStyles(styles, muiOptions)(_DuplicateDealDialog)